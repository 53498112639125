button {
    // button reset
    display: inline-block;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
}

.btn {
    display: inline-block;
    height: 50px;
    border-radius: 25px;
    padding: 0 38px;
    border: 1px solid transparent;
    text-transform: uppercase;
    .transition;
    text-decoration: none;
    font-family:'Nunito', sans-serif;
    cursor:pointer;

    &.btn-primary {
        background-color: @brc;
        .font-style(13px,@hc,700,50px,center);

        &:hover{
            background-color: @hc;
            color:@brc;
            text-decoration: none;
        }

        &:focus,
        &:active {
            background-color: @hc;
            color:@brc;
            text-decoration: none;
        }
    }

    &.btn-simple {
        background-color: transparent;
        border-color:@hc;
        .font-style(13px,@hc,700,50px,center);
        padding: 0 50px;

        &:hover{
            border-color:@brc;
            text-decoration: none;
        }

        &:focus,
        &:active {
            border-color:@brc;
            text-decoration: none;
        }
    }

    // colors

    &_white {

    }

    // states

    &.icon-btn {
        padding: 0 60px 0 20px; 
        position: relative;

        &:hover {
            border-color:@hc;

            .icon-svg {
                svg path {
                    fill:inherit;
                    .transition;
                }

                use.right-arrow {
                    fill:@brc;
                }
            }
        } 

        .fa {
            position: absolute;
            right:20px;
            top:50%;
            transform:translateY(-50%);
            color:@hc;
            .transition;
            font-size: 24px;
            cursor:pointer;
        }

        .icon-svg {
            width: 19px;
            height:21px;
            position: absolute;
            right:30px;
            top:7px;
            .transition;
            
            svg path {
                fill:inherit;
                .transition;
            }

            use.right-arrow {
                fill:@hc;
                .transition;
            }
        }
    }

    // sizes

    &_md {

    }

    &_sm {

    }

    &_xs {

    }
}