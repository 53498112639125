.basket {
	position: relative;
	padding-top: 195px;
	padding-bottom: 110px;

	&-img-1 {
		position: absolute !important;
		left:auto !important;
		top:216px !important;
		right:-20px;
		width: 72px;
	}

	&-img-2 {
		position: absolute !important;
		left:448px !important;
		top:100px !important;
		width: 16px;
		height:16px;

		img {
			width: 100%;
			height:100%;
		}
	}

	&-img-3 {
		position: absolute !important;
		left:0px !important;
		top:688px !important;
	}

	&-img-4 {
		position: absolute !important;
		left:auto !important;
		top:auto !important;
		bottom:142px !important;
		right:0 !important;
		width: 16px;
		height:16px;

		img {
			width: 100%;
			height:100%;
		}
	}

	&-img-5 {
		position: absolute !important;
		left:164px !important;
		top:auto !important;
		bottom:45px !important;
		width: 16px;
		height:16px;

		img {
			width: 100%;
			height:100%;
		}
	}

	&-img-6 {
		position: absolute !important;
		left:auto !important;
		top:auto !important;
		right:205px !important;
		bottom:-150px !important;
		width: 60px;
		height:60px;

		img {
			width: 100%;
			height:100%;
		}
	}


	&__h {
		padding-bottom: 75px;

		h2 {
			.font-style(30px,#fff,400,1.2,left);
		}
	}

	&__cont {
		position: relative;
	}

	&-head {
		position: relative;
		.cf();
		padding-left: 0;
		padding-bottom: 25px;
		list-style:none;

		&__item {
			float:left;
			.font-style(18px,#fff,400,1.2,left);

			&.prod {
				width: 50.7%;
			}

			&.price {
				width: 14.5%;
			}

			&.quant {
				width: 17.6%;
			}

			&.quant-big {
				width: 22%;
			}

			&.total {
				width: 16.88%;
			}

			&.total-small {
				width: 10.88%;
			}
		}
	}

	&-menu {
		padding-left: 0;
		.cf();
		border-bottom:1px solid #242424;
		border-left:1px solid #242424;
		border-right:1px solid #242424;
		background-color: #101010;
		list-style:none;
		height:120px;

		&:first-child {
			border-top:1px solid #242424;
		}

		&__pict {
			float:left;
			width: 14.8%;
			padding-left: 25px;
			height:100%;
			position: relative;
		}

		&__hprice {
			width: 50.3%;
			float:left;
			position: relative;
			height:100%;
		}

		&__h {
			width: 71%;
			float:left;
			position: relative;
			height:100%;
		}

		&__price {
			width: 29%;
			float:left;
			position: relative;
			height:100%;
		}

		&__quant {
			width: 17.5%;
			float:left;
			position: relative;
			height:100%;

			&.m-big {
				width:22%;
			}

			.basket-menu__desc {
				float:left;
				padding-left: 19px;
				padding-right: 19px;

				&.center {
					padding-left: 0;
					padding-right: 0;
					width: 75px;

					p {
						text-align: center;
					}
				}
			}
		}

		&__total {
			width: 12.3%;
			float:left;
			position: relative;
			height:100%;
		}

		&__del {
			width: 4.5%;
			float:left;
			position: relative;
			height:100%;
		}

		&__icon {
			width: 15px;
			height:17px;
			cursor:pointer;

			&:hover {

				use.delete-icon {
					fill:@brc;
				}

			}

			svg path {
				fill:inherit;
				.transition;
			}

			use.delete-icon {
				.transition;
				fill:#686868;
			}
		}

		&__ico {
			width: 15px;
			height:15px;
			position: relative;
			float:left;
			.transition;
			cursor:pointer;
			margin-top:5px;

			&:hover {

				use.plus-icon {
					fill:#fff;
				}
			}

			svg path {
				fill:inherit;
			}

			use.plus-icon {
				fill: #5A5A5A;
				.transition;
			}
			/* border:1px solid #5A5A5A;
			border-radius:50%;
			width: 15px;
			height:15px;
			.transition;
			float:left;
			position: relative;
			cursor:pointer;
			margin-top: 5px;
			
			&.top-f {
				padding-top: .2px;
			}
			
			&.top-s {
				padding-top: 1px;
			}
			
			&:hover {
				border-color:#fff;
			
				p {
					color:#fff;
				}
			}
			
			p {
				.font-style(10.5px,#5A5A5A,400,1.2,center);
				.transition;
			} */
		}


		&__cont {
			position: absolute;
			top:50%;
			left:0;
			width: 100%;
			transform:translateY(-50%);
			.cf();
		}

		&__img {
			width: 70px;
			margin-left: 25px;
		}

		&__desc {

			p {
				.font-style(20px,#fff,400,1.2,left);
				margin:0;
			}
		}
	}

	&-check {
		padding-top: 60px;
		text-align: right;

		&__total {
			padding-bottom: 60px;

			p {
				.font-style(30px,#fff,400,1.2,right);

				span {
					font-weight: 700;
				}
			}
		}
	}
}


@media screen and (max-width: 992px) {

	.basket-menu__quant.m-big {
		width: 22%;
	}

	.checkout-right.big-pad {
		padding-bottom: 60px;
	}

}

@media screen and (max-width: 768px) {

	.basket-head {
		display: none;

		&.mob {
			display: block;

			.basket-head__item {
				display: none;

				&.prod {
					display: block;
					width: 75%;
				}

				&.quant-big {
					width: 25%;
					display: block;
				}
			}
		}
	}
	
	.basket-menu {

		&__h {
			float:none;
			padding-bottom: 5px;

			.basket-menu__desc {

				p {
					color:#686868;
				}
			}
		}

		&__hprice {
			width: 45%;

			&.xs-big {
				width: 55%;
			}
		}

		&__price {
			float:none;
		}

		&__pict {
			width: 20%;
		}

		&__quant {
			width: 25%;
		}

		&__total {
			display: none;
		}

		&__del {
			width: 9.5%;
			border-left:1px solid #242424;
		}

		&__icon {
			margin:0 auto;
		}
	}


}

@media screen and (max-width: 480px) {

	.basket-head {

		&.mob {

			.basket-head__item {

				&.prod {
					width: 78%;
				}

				&.quant-big {
					width: 20%;
				}
			}
		}
	}

	.basket {
		padding-bottom: 60px;
		padding-top: 110px;

		&__h {
			padding-bottom: 30px;
		}


	}

	.basket-menu {

		&__quant {
			width: 10%;

			.basket-menu__desc {
				float:none;
				padding-left: 0;
				padding-right: 0;
				padding-top: 5px;
				padding-bottom: 5px;
				padding-left: 3px;
			}
		}

		&__hprice {
			width: 50%;

			&.xs-big {
				width: 50%;
			}
		}

		&__quant.m-big {
			width: 20%;
		}

		&__pict {
			width: 30%;
		}

		&__ico {
			margin-top: 0;
			float:none;
		}

		&__img {
			width: 55px;
			margin-left: 15px;
		}

		&__desc {

			p {
				font-size: 18px;
			}
		}
	}

}

@media screen and (max-width: 380px) {

	.basket-check {
		padding-top: 30px;

		&__total {
			text-align: left;
			padding-bottom: 30px;

			p {
				.cf();
				font-size: 20px;
				text-align: left;

				span {
					float:right;
				}
			}
		}

		.btn {
			display: block;
			width: 100%;
		}
	}
}


@media screen and (max-width: 360px) {

	.basket-menu__desc p {
		font-size: 16px;
	}

}
