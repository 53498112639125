.blog-post {
	position: relative;
	background-color: #FEFEFE;
	padding-top: 180px;
	padding-bottom: 120px;

	&__h {
		padding-bottom: 30px;

		h2 {
			.font-style(29px,@bc,700,1.6,justify);
		}
	}

	&__img {
		width: 95%;
		height:432px;
		position: relative;
	}

	&__data {
		position: absolute;
		left:0px;
		top:0px;
		background-color: @brc;
		width: 71px;
		height:34px;

		p {
			.font-style(14px,@hc,700,37px,center);
		}
	}

	&__desc {
		position: relative;
		
		p {
			.font-style(18px,@tc,400,2,left);
			padding-bottom: 25px;
			letter-spacing: -.2px;

			&.pd-bot {
				padding-bottom: 50px;
			}
		}
	}

	&__wrap {
		.cf();
	}

	&__img {
		float:right;
	}

	&__txt {
		float:left;
	}
}

@media screen and (max-width: 1200px) {

	.blog-post {	
		padding-bottom: 50px;

		&__img {
			width: 100%;
			margin-bottom: 30px;
		}
	}

}