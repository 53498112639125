.locations {
	position: relative;
    padding-top: 210px;
    overflow: hidden;

    &-bg-1 {
        position: absolute !important;
        top:125px !important;
        left:96% !important;
        width: 72px;
    }

    &-bg-2 {
        position: absolute;
        left:0px !important;
        top:1000px !important;
    }


    &-bg-3 {
        position: absolute;
        top:auto !important;
        bottom:636px !important;
        right:0 !important;
        left:auto !important;
        width: 35px;
        height:35px;

        img {
            width: 100%;
            height:100%;
        }
    }

    &-bg-4 {
        position: absolute;
        top:auto !important;
        bottom:240px !important;
        left:30px !important;
        width: 16px;
        height:16px;

        img {
            width: 100%;
            height:100%;
        }

    }

    @media screen and (max-width: 992px) {
    
        .locations {

            &-bg-1, &-bg-2, &-bg-3, &-bg-4 {
                display: none !important;
            }
        }

    }
}

.location {
	position: relative;
    padding-bottom: 168px;

    &-bg-1 {
        position: absolute;
        left:25px !important;
        top:40px !important;
    }

    &-bg-2 {
        position: absolute;
        left:442px !important;
        top:0px !important;
        width: 16px;
        height:16px;

        img {
            height:100%;
            width: 100%;
        }
    }

    &-bg-3 {
        position: absolute;;
        top:auto !important;
        bottom:-2px !important;
        left:350px !important;

        img {
            transform:rotate(25deg);
        }
    }

    &-bg-4 {
        position: absolute;
        top:auto !important;
        bottom:-30px;
        right:0px;
        left:auto !important;
        width: 60px;
        height:60px;

        img {
            height:100%;
            width: 100%;
            transform:rotate(25deg);
        }
    }

    &-bg-5 { 
        position: absolute;;
        top:70px !important;
        right:364px !important;
        left:auto !important;
    }

    &-bg-6 {
        position: absolute;
        top:70px !important;
        right:0px !important;
        left:auto !important;
        width: 16px;
        height:16px;

        img {
            height:100%;
            width: 100%;
        }
    }

    &-bg-7 {
        position: absolute;
        top:auto !important;
        bottom:-50px !important;
        right:150px !important;
        left:auto !important;
        width: 90px;
        height:90px;

        img {
            height:100%;
            width: 100%;
            transform:rotate(25deg);
        }
    }

    .right {
        float:right;
        z-index: 1;
        position: relative;

        .location__txt {
            padding-right: 20px;
        }
    }

	&__h {
		padding-bottom: 25px;
        padding-top: 53px;

		h2 {
			.font-style(36px,@hc,400,1.35,left);
		}
	}

	&__desc {
		padding-bottom: 42px;

		p {
			.font-style(18px,@tc,400,2,left);
		}
	}

	&__item {
		padding-bottom: 27px;

        &:after {
            content:"";
            display: inline-block;
            vertical-align: middle;
            height:100%;
        }

        .icon-svg {
            width: 19px;
            height: 19px;
            display: inline-block;
            vertical-align: middle;
            position: relative;

            svg path {
                fill:inherit;
            }

            use.foot-ico {
                fill:#fff;
                .transition;
            }
        }

        p {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            .font-style(18px,@hc,400,1.2,center);
            padding-left: 12px;
        }
	}

    &__txt {
        padding-left: 20px;
    }

    &__map {
        padding-top: 15px;
        cursor:pointer;

        &:hover .icon-svg {
            svg path {
                fill:inherit;
                .transition;
            }

            use.right-arrow {
                fill:@brc;
                .transition;
            }
        }

        &:after {
            content:"";
            display: inline-block;
            vertical-align: middle;
            height:100%;
        }

        a {
            .font-style(14px,@hc,400,1.2,center);
            display: inline-block;
            vertical-align: middle;
            position: relative;
            text-transform: uppercase;

            &:hover, &:focus {
                text-decoration: none;
            }
        }

        .icon-svg {
            padding-left: 12px;
            display: inline-block;
            vertical-align: middle;
            position: relative;
            width: 28px;
            height:20px;

            svg path {
                fill:inherit;
                .transition;
            }

            use.right-arrow {
                fill:#fff;
            }
        }
    }

    @media screen and (max-width: 1200px) {
        
        .location__h {
            padding-top: 0;
        }

    }

    @media screen and (max-width: 992px) {
        
        .location__h {
            padding-top: 50px;

            h2 {
                text-align: center;
            }
        }

        .location__desc p {
            text-align: center;
        }

        .location {
            text-align: center;

            &__txt {
                width: 550px;
                margin:0 auto;
            }

            &__items {
                width: 270px;
                margin:0 auto;
            }

            &__item, &__map {
                text-align: left;
            }

            &-img {
                display: inline-block;
            }

            &-bg-1, &-bg-2, &-bg-3, &-bg-4, &-bg-5, &-bg-6, &-bg-7, &-bg-8 {
                display: none !important;
            }
        }

    }

    @media screen and (max-width: 768px) {
    
        .location {

            &__txt {
                width: 100%;
            }
        }

    }

    @media screen and (max-width: 480px) {
    
        .location {

            &__items {
                width: auto;
                margin:0
            }

            &__txt {
                padding-left: 15px;
                padding-right: 15px;
            }

            &__h {
                padding-bottom: 30px;

                h2 {
                    font-size: 24px;
                }
            }

            &__desc {
                padding-bottom: 30px;

                p {
                    font-size: 17px;
                }
            }

            &__item {
                padding-bottom: 15px;
                flex-direction: row;
                justify-content: flex-start;
                display: flex;
                p{
                    text-align: left;
                    padding-left: 15px;
                    max-width: 235px;
                }
            }
        }

    }
}

@media screen and (max-width: 992px) {

    .locations {
        padding-top: 200px;
    }

    .location {
        padding-bottom: 100px;

        .right {
            float:none;
        }
    }
}

@media screen and (max-width: 480px) {

    .locations {
        padding-top: 130px;
    }

}

@media screen and (max-width: 360px) {

    .location__item {

        p {
            font-size: 16px;
        }
    }

}