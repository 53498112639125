
.blog-count {
    display: none;
    position: relative;
    margin-bottom: 70px;

    &__item {
        .font-style(16px,@bc,600,1.2,center);
    }

    &__items {
        .font-style(16px,#A8A8A8,600,1.2,center);
    }

    &__sep {
        padding-left: 6px;
        padding-right: 6px;
        .font-style(17px,#A8A8A8,600,1.2,center);
    }
}

.blog-footer {
    position: relative;
    background-color: #F6F6F6;
    padding-top: 60px;
    padding-bottom: 85px;

    &__h {
        position: relative;
        text-align: center;
        margin-bottom: 80px;

        h3 {
            .font-style(24px,@bc,700,1.6,center);
            text-transform: uppercase;
            display: inline-block;
            position: relative;
            z-index: 2;

            &:after {
                content:"";
                position: absolute;
                width: 130%;
                transform:translateX(-50%);
                left:50%;
                top:0;
                height:100%;
                z-index: -1;
                background-color:#F6F6F6;
            } 
        }

        &:after {
            content:"";
            position: absolute;
            width: 100%;
            height:2px;
            background-color: #BEBEBE;
            left:0;
            top:50%;
            transform:translateY(-50%);
            z-index: 0;
        }
    }

    &-back {
        position: relative;
        padding-top: 30px;
        text-align: center;

        &:after {
            content:"";
            width: 0;
            height:100%;
            display: inline-block;
        }

        &:hover {

            .blog-footer-back__icon {

                use.right-arrow {
                    fill:@brc;
                }
            }
        }

        &__icon {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            transform:rotate(-180deg);
            width: 20px;
            height:20px;

            svg path {
                fill:inherit;
                .transition;
            }

            use.right-arrow {
                fill:@bc;
                .transition;
            }
        }

        &__desc {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            padding-left: 10px;

            a {
                .font-style(18px,@bc,600,1.2,center);
                text-transform: uppercase;
                text-decoration: none;
            }
        }
    }
}

.blog-slider {
    position: relative;
    margin: 0 -18px;
    width: auto;

    &__item {
        padding-bottom: 50px;

        .blog-item {
            margin:0 auto;
            max-width: 360px;

            &:hover {
                box-shadow:0 0px 30px -1px rgba(0, 0, 0, 0.1);
            }
        }
    }

    .owl-nav {
        position: absolute;
        left:0;
        top:50%;
        display: block;
        .cf();
        width: 100%;
        transform:translateY(-50%);
        z-index: -1;
        height:15px;

        &.disabled {
            display: block;
        }

        .owl-prev {
            left:-55px;
        }

        .owl-next {
            right:-55px;
        }

        .owl-prev, .owl-next {
            position: absolute;
            top:0;
            cursor:pointer;

            i {
                border: solid #7C7C7C;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 3.5px;
                .transition;

                &.right {
                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    left:0px;
                    top:0px;
                    position: relative;
                }

                &.left {
                    transform: rotate(135deg);
                    -webkit-transform: rotate(135deg);
                    right:0px;
                    top:0px;
                    position: relative;
                }
            }

            &:hover i {
                border-color:@brc;
            }
        }
    }
}

@media screen and (max-width: 1300px) {

    .blog-slider {
        
        .owl-nav {
            .owl-next {
                right:-10px;
            }

            .owl-prev {
                left:-10px;
            }
        }
    }

}


@media screen and (max-width: 1200px) {

    .blog-slider {
        margin:0px;
        width: auto;
        
        .owl-nav {
            display: none;
        }

        &__item {

            .blog-item {
                width: 100%;
            }
        }

        &.owl-carousel .owl-stage-outer {
            overflow: visible;
        }
    }

    .blog-slider__item {

        .blog-item {
            max-width:300px;
        }
    }

}


@media screen and (max-width: 992px) {

    .blog-count {
        display: block;
        text-align: center;
        margin-bottom: 60px;
    }

    .blog-post {
        padding-top: 120px;

        &__img {
            height:300px;
        }

        &__h {
            padding-bottom: 20px;

            h2 {
                font-size: 20px;
            }
        }

        &__desc {

            p {
                font-size: 16px;
                padding-bottom: 20px;

                &.pd-bot {
                    padding-bottom: 20px;
                }
            }
        }
    }

    .blog-footer__h {

        h3 {
            font-size: 20px;

            &:after {
                width: 115%;
            }
        }
    }

    .blog-slider__item {
        
        .blog-item {
            max-width:100%;
        }
    }

    .blog-footer-back {
        padding-top: 0;
    }

    .blog-footer {
        padding-bottom: 50px;

        &__h {
            margin-bottom: 50px;
        }
    }

}


@media screen and (max-width: 480px) {

    .blog-slider {
        margin-right: 10px;
    }

    .blog-slider__item {
        
        .blog-item {
            max-width:100%;
        }
    }

    .blog-post__h h2 {
        font-size: 18px;
    }

    .blog-post__img {
        height:230px;
    }

}