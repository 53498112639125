.checkout {
	position: relative;
	padding-top: 195px;
	padding-bottom: 110px;

	&-img-1 {
		position: absolute !important;
        right:-20px !important;
        left:auto !important;
        top: 188px !important;
        width: 56px;
	}

	&-img-2 {
        position: absolute !important;
        left: 0px !important;
        top: 600px !important;
    }

    &-img-3 {
    	position: absolute !important;
	    left: 206px !important;
	    top: 100px !important;
	    width: 16px;
	    height: 16px;

	    img {
	    	width: 100%;
	    	height:100%;
	    }
    }

    &-img-4 {
    	position: absolute !important;
	    left: auto !important;
	    top: 264px !important;
	    right:0 !important;
	    width: 70px;
	    height: 90px;

	    img {
	    	width: 100%;
	    	height:100%;
	    }
    }

	&__h {
		padding-bottom: 75px;

		h2 {
			.font-style(30px,#fff,400,1.2,left);
		}
	}

	&-left {
		float:left;
		position: relative;
		padding-top: 12px;
	}

	&-right {
		float:right;
		position: relative;
		padding-top: 12px;
		width: 300px;

		&.big-pad {
			padding-top: 60px;
			padding-bottom: 110px;
		}
	}

	&-field {
		position: relative;
		width: 305px;
		max-width:100%;

		input {
			border: 1px solid #474747;
			padding-right: 90px;
			width: 100%;
		    display: block;
		    width: 100%;
		    resize:none;
		    padding-left:37px;
		    background: transparent;
		    color: @tcl;
		    font-weight: 300;
		    font-size: 16px;
		    height:50px;
		    line-height: normal;
		    position: relative;
		    border-radius: 30px;
		    outline: none;
		    .transition;

		    &:focus, &:hover {
				border-color:@tcl;
		    }

		    &:focus + label {
		    	opacity: 1;
		    }
		}

		&__btn {
			position: absolute;
			right:0;
			top:0;
			height:100%;
			width: 100px;
			.font-style(14px,#fff,400,50px,center);
			border-radius:30px;
			border:1px solid #fff;
			text-transform: uppercase;
			.transition;

			&:hover, &:focus {
				text-decoration: none;
				background-color: @brc;
				border-color:@brc;
			}
		}
	}

	&-back {
		.cf();
		position: relative;
		margin-top: 100px;

		&.show-xs {
			display: none;
		}

		&:hover {

			.icon-svg {

				svg path {
					fill:inherit;
				}
				
				use.left-arrow {
					fill:@brc;
				}
			}
		}

		.icon-svg {
			float:left;
			height:20px;
			width: 20px;
			transform:rotate(-180deg);

			svg path {
				fill:inherit;
			}

			use.left-arrow {
				fill:#A0A0A0;
				.transition;
			}
		}

		&__desc {
			.font-style(18px,#A0A0A0,400,1.2,left);
			text-transform: uppercase;
			display: block;
			margin-left: 20px;
			float:left;

			&:focus, &:hover {
				text-decoration: none;
				color:#A0A0A0;
			}
		}
	}

	&__desc {
		position: relative;
		width: 100%;
		padding-bottom: 7px;

		p {
			.font-style(24px,#A0A0A0,400,1.2,left);
			text-transform: uppercase;
			.cf();

			span {
				float:right;
			}

			&.bold {
				font-weight: 700;
				color:#fff;

				span {
					font-weight: 700;
				}
			}
		}
	}

	.btn {
		margin-top: 45px;
		width: 100%;
		display: block;
	}
}

.form-block {
	position: relative;
	background-color: #141414;
	border-radius:30px;
	padding: 46px 70px;
	margin-bottom: 37px;
	padding-bottom: 70px;

	&__h {
		padding-bottom: 35px;

		&.big-pad {
			padding-bottom: 50px;
		}

		h3 {
			.font-style(22px,#fff,400,1.2,left);
			text-transform: uppercase;
		}
	}

	&__wrap {
		.cf();
		position: relative;
	}

	&__item {
		float:left;
		width: 50%;
		position: relative;

		&:first-child {
			padding-right: 12.5px;
		}

		&:last-child {
			padding-left: 12.5px;
			padding-bottom: 0;
		}
	}

	&__field {
		position: relative;
		padding-bottom: 30px;
		color: #fff;

		&:last-child {
			padding-bottom: 0;
		}

		input, textarea {
			border: 1px solid #474747;
		    display: block;
		    width: 100%;
		    resize:none;
		    padding-left:20px;
		    background: transparent;
		    color: @tcl;
		    font-weight: 300;
		    font-size: 18px;
		    height:60px;
		    line-height: normal;
		    position: relative;
		    border-radius: 4px;
		    outline: none;
		    .transition;

		    &:focus, &:hover {
				border-color:@tcl;
		    }

		    &:focus + label {
		    	opacity: 1;
		    }
		}

		textarea {
			height:189px;
			padding-top: 20px;
		}

		label {
			.font-style(18px,#898989,300,1.2,left);
			margin-bottom: 15px;
			.transition;
		}

		&.lab-none {

			label {
				position: absolute;
				top:4px;
				font-size: 12px;
				left:20px;
				opacity: 0;
			}
		}

		.jq-selectbox {
		    width: 100%;
		}

		.jq-selectbox__select {
			border:1px solid #474747;
		    padding:0;
		    padding-left: 20px;
		    box-shadow: none;
		    font:18px 'Source Sans Pro', sans-serif;
		    font-weight: 400;
		    background: transparent;
		    text-overflow: ellipsis;
		    display: block;
		    overflow: hidden;
		    white-space: nowrap;
		    background-position: right 0;
		    height: 60px;
		    line-height: 60px;
		    padding-right: 30px;
		    cursor: pointer;
		    width: auto !important;
		    display: block;
		    font-size: 18px;
		    color: #BEBEBE;
		    text-align: left;
		    width: 100%;
		    transition:all .3s ease-in;
		    -webkit-transition:all .3s ease-in;
		    text-shadow: none;
		    text-transform: capitalize;
		    border-radius:4px;

		    &:hover {
		    	background-color: #080808;
		    	border:1px solid #242424;
		    }
		}

		.jq-selectbox.opened .jq-selectbox__select, .dark .jq-selectbox.focused .jq-selectbox__select {
		    background-color: #080808;
		    border:1px solid #242424;
		}

		.jq-selectbox.opened, .dark .jq-selectbox.focused {
		    border:none !important;
		    outline: none;
		}

		.jq-selectbox__dropdown {
		    margin:0;
		    top:60px !important;
		    background-color: transparent;
		    border:none;
		    width: 100% !important;
		    box-shadow:none;

		    ::-webkit-scrollbar-track {
			    width: 1px;
			    background-clip: padding-box;
			    background-color:#080808;
			}

			::-webkit-scrollbar-thumb {
			    background-color:#ecedee;
			    border-radius: 0px;
			    background-clip: padding-box;
			}

			::-webkit-scrollbar {
			    width: 4px;
			}
		}

		.jq-selectbox__dropdown ul {
		    /* overflow-y: scroll !important; */
		    background-color: #080808;
		    border:1px solid #242424;
		    width: 100%;
		    max-height:180px;
		    border-radius:4px;
		    padding-left: 0;
		}

		.jq-selectbox__trigger-arrow {
		    display: none;
		}

		.jq-selectbox__dropdown ul li { 
		    background-color: transparent;
		    padding:0;
		    color:#565656;
		    padding-left: 20px;
		    width: 100%;
		    font-size: 18px;
		    height:60px;
		    font-weight: 400;
		    line-height: 60px;
		    border-bottom:none;
		    transition:all .3s ease-in;
		    -webkit-transition:all .3s ease-in;
		    border-bottom:1px solid #242424;
		    text-transform: capitalize;
		    cursor:pointer;
		}

		.jq-selectbox__dropdown ul li:last-child {
		    border-bottom:none;
		}

		.jq-selectbox__dropdown ul li:hover {
		    color:@brc;
		}

		.jq-selectbox__trigger {
		    border: solid #828282;
		    border-width: 0 2px 2px 0;
		    display: inline-block;
		    padding: 5px;
		    transform: rotate(45deg);
		    -webkit-transform: rotate(45deg);
		    background-image: none;
		    width: 10px;
		    height:10px;
		    right:20px;
		    top: 21px;
		    position: absolute;
		}
	}

	&__phone {
		.cf();
		position: relative;

		.jq-selectbox {
			position: relative;
			width: 110px;
		}

		&-field {
			position: absolute;
            right:0;
            top:0px;
            width:auto;
            left: 130px;
		}
	}
}


@media screen and (max-width: 768px) {

	.form-block {
		padding:50px;

		&__field {
			padding-bottom: 20px;
		}

		&__item {
			float:none;
			width: 100%;
			padding-bottom: 20px;

			&:first-child {
				padding:0;
				padding-bottom: 20px;
			}

			&:last-child {
				padding:0;
			}
		}

		&__h.big-pad {
			padding-bottom: 35px;
		}
	}

	.checkout-left {
		float:none;
		margin:0 auto;
		width: 300px;
		max-width:100%;
	}

	.checkout-right {
		float:none;
		margin:0 auto;
		width: 300px;
		padding-top: 45px;
		max-width:100%;
	}

	.checkout-back {
		display: none;
		text-align: center;
		margin-top: 45px;

		&.show-xs {
			display: block;

			&:after {
				content:"";
				display: inline-block;
				width: 0;
				height:100%;
			}
		}

		a {
			float:none;
			display: inline-block;
			vertical-align: middle;
			position: relative;
		}

		.icon-svg {
			float:none;
			display: inline-block;
			vertical-align: middle;
			position: relative;
		}
	}
}

@media screen and (max-width: 480px) {

	.form-block {
		padding:30px;

		&__h {

			h3 {
				font-size: 18px;
			}
		}

		&__phone {

			.jq-selectbox {
				width: 105px;
			}

			&-field {
	            left: 115px;
			}
		}
	}

	.checkout {
		padding-bottom: 60px;
		padding-top: 110px;

		&__h {
			padding-bottom: 30px;
		}

		&__desc {

			p {
				font-size: 20px;
			}
		}
	}

}
p.error-message{
	display: none;
	margin-top: 5px;
    color: #ea0029;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}