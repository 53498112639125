.main-footer{
    background-color: #050607;
    padding-top: 45px;
    position: relative;
    overflow: hidden;

    &.bg-img {

        .main-footer__img {
            display: block;
        }
    }

    &.bg-img-small {

        .main-footer-small-1 {
            display: block;
        }

        .main-footer-small-2 {
            display: block;
        }
    }

    &-small-1 {
        position: absolute !important;
        top:-200px !important;
        left:20px !important;
    }

    &-small-2 {
        position: absolute !important;
        top:-200px !important;
        left:575px !important;
    }

    &__img {
        position: absolute !important;
        top:300px !important;
        left:400px !important;
        opacity: .02;
        display: none;
    }

    &-block {

        &.second {
            padding-left: 55px;
        }
        
        &.last {
            float:right;
        }

        &__h {
            margin-top: 60px;
            padding-bottom: 40px;

            h2 {
                .font-style(24px,@brc,400,1.2,left);
            }
        }

        &__item {
            padding-bottom: 27px;

            &:after {
                content:"";
                display: inline-block;
                vertical-align: middle;
                height:100%;
            }

            .icon-svg {
                width: 19px;
                height: 19px;
                display: inline-block;
                vertical-align: middle;
                position: relative;
                float:left;
                margin-top: 1.5px;

                svg path {
                    fill:inherit;
                    .transition;
                }

                use.foot-ico {
                    fill:#fff;
                }
            }

            p {
                display: inline-block;
                vertical-align: middle;
                position: relative;
                .font-style(18px,@hc,400,1.2,left);
                padding-left: 7px;
                float:left;
                max-width:91%;
            }
        }
    }

    &-map {
        padding-top: 25px;
        cursor:pointer;

        &:hover .icon-svg {

            svg path {
                fill:inherit;
                .transition;
            }

            use.right-arrow {
                fill:@brc;
                .transition;
            }
        }

        &:after {
            content:"";
            display: inline-block;
            vertical-align: middle;
            height:100%;
        }

        a {
            .font-style(14px,@hc,400,1.2,center);
            display: inline-block;
            vertical-align: middle;
            position: relative;
            text-transform: uppercase;
            padding-top: 0px;

            &:focus, &:hover {
                text-decoration: none;
            }
        }

        .icon-svg {
            padding-left: 12px;
            display: inline-block;
            vertical-align: middle;
            position: relative;
            width: 28px;
            height:20px;

            svg path {
                fill:inherit;
                .transition;
            }

            use.right-arrow {
                fill:#fff;
            }
        }
    }

    @media screen and (max-width: 1200px) {

        .main-footer-block.second {
            padding-left: 0;
        }

        .post-footer {

            &-menu {

                &__item {

                    a {
                        font-size: 12px;
                    }
                }
            }
        }
        
    }

    @media screen and (max-width: 992px) {

        .main-footer {

            &-block {

                &__h {
                    padding-bottom: 35px;

                    h2 {
                        font-size: 19px;
                    }
                }

                &__item {
                
                    .icon-svg {
                        width: 16px;
                        height: 18px;
                    }

                    p {
                        font-size: 16px;
                    }
                }
            }

            &-map {
                padding-top: 0px;
            }
        }
    }

    @media screen and (max-width: 768px) {

        .main-footer-block {
            width: 276px;
            margin:0 auto;
            padding-bottom: 50px;

            &.last {
                float:none;
                padding-bottom: none;
            }

            &__h {

                h2 {
                    font-size: 24px;
                }
            }

            &__item {
                
                .icon-svg {
                    width: 19px;
                    height: 18px;
                }

                p {
                    font-size: 18px;
                }
            }
        }

    }

    @media screen and (max-width: 480px) {

        .main-footer-block {
            width:100%;
        }

    }

    @media screen and (max-width: 360px) {

        .main-footer-block {
            width:100%;

            &__h {

                h2 {
                    font-size: 20px;
                }
            }

            &__item {

                p {
                    font-size: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {

    .main-footer {
        padding-top: 50px;
    }

}

@media screen and (max-width: 480px) {

    .main-footer {
        padding-top: 0px;
        padding-left: 15px;
        padding-right: 15px;

        .main-footer-block {
            padding-bottom: 25px;
        }
    }

}

.post-footer {
    position: relative;
    padding-top: 118px;
    padding-bottom: 70px;
    .cf();

    &-soc {
        float:left;
        
        &__ico {
            float:left;
            width: 25px;
            height: 25px;
            position: relative;
            display: block;
            .transition;

            &:hover {

                svg path {
                    fill:inherit;
                    .transition;
                }

                use.footer-ico {
                    fill:#fff;
                    .transition;
                }
            }

            &:last-child {
                margin-left: 25px;
            }

            svg path {
                fill:inherit;
                .transition;
            }

            use.footer-ico {
                fill:@tc;
                .transition;
            }
        }
    }

    &-menu {
        position: relative;
        padding-left: 44px;
        float:left;
        list-style: none;
        padding-top: 5px;
        .cf();

        li {
            position: relative;
            float:left;
            padding-left: 30px;

            a {
                .font-style(14px,#B8B8B8,400,1.2,left);
                .transition;
                text-transform: uppercase;

                &:hover {
                    color:@hc;
                    text-decoration: none;
                }
            }
        }
    }

    &-create {
        float:right;
        font-family: 'Montserrat', sans-serif;
        cursor:pointer;
        padding-top: 4px;

        &:hover {

            p {
                a {
                    color:@brc;
                    text-decoration: none;
                }
            }
        }

        p {
            .font-style(14px,@tc,300,1.2,left);
            font-family: inherit;
            .transition;

            a {
                color:#B8B8B8;
                font-weight: 700;
                .transition;
            }
        }
    }
}

    @media screen and (max-width: 992px) {
        
        .post-footer {
            padding-top: 25px;

            &-soc {
                float:none;
                text-align: center;

                &__ico {
                    float:none;
                    display: inline-block;
                }
            }

            &-menu {
                float:none;
                text-align: center;
                padding-left: 0;

                li {
                    float:none;
                    display: inline-block;

                    &:first-child {
                        padding-left: 0;
                    }
                }
            }

            &-create {
                float:none;
                padding-top: 40px;

                p {
                    text-align: center;
                }
            }
        }
    }

    @media screen and (max-width: 992px) {
    
        .post-footer {

            &-menu {
                display: none;
            }
        }

    }

    .main-footer-block__item {
        .clearfix();
    }