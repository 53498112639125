.blog {
	position: relative;
	background-color: #f6f6f6;
	padding-top: 202px;
	padding-bottom: 75px;

	&-bg-1 {
		position: absolute !important;
		top:586px !important;
		left:-18px !important;
		width: 60px;
		height:60px;

		.img {
			transform:rotate(90deg);
			width: 100%;
			height:100%;
		}

		img {
			width: 100%;
			height:100%;
		}
	}

	&-bg-2 {
		position: absolute;
		top:992px !important;
		left:auto !important;
		right:-34px !important;
		width: 110px;
		height:110px;

		img {
			width: 100%;
			height:100%;
		}
	}

	&-bg-3 {
		position: absolute;
		top: 1145px !important;
    	left: -68px !important;
    	height:100px;
    	width: 100px;

		.img {
			width: 100%;
			height:100%;
			transform:rotate(-90deg);
		}

		img {
			width: 100%;
			height:100%;
		}
	}

	&-bg-5 {
		position: absolute;
		top:295px !important;
		left:525px !important;
		width: 16px;
		height:16px;

		.img {
			transform:rotate(-15deg);
			width: 100%;
			height:100%;
		}

		img {
			width: 100%;
			height:100%;
		}
	}

	&-bg-4 {
		position: absolute;
		top:1445px !important;
		left:auto !important;
		right:-72px !important;
		height:100px;
    	width: 100px;

		.img {
			width: 100%;
			height:100%;
			transform:rotate(90deg);
		}

		img {
			width: 100%;
			height:100%;
		}
	}

	&-search {
		position: relative;
		background-color: transparent;
		width: 300px;

		&:hover {

			input {
				.placeholder(@bc);
				color:@bc;
				border-bottom-color:#767676; 
			}


			use.search {
				fill:@bc;
			}
		}

		input {
			border:none;
			outline: none;
			.placeholder(#a8a8a8);
			color:#a8a8a8;
			padding-bottom: 10px;
			.transition;
			font-size: 24px;
			font-weight: 400;
			padding-left: 8px;
			line-height: normal;
			height: 40px;
			border-bottom:2px solid #a8a8a8;
			background-color: transparent;
			position: relative;
			width: 100%;
			border-radius:0 !important;

			&:hover {
				.placeholder(@bc);
				color:@bc;
			}
		}

		&__icon {
			position: absolute;
			right:19px;
			width: 25px;
			height:25px;
			top:1px;
			.transition;

			svg path {
				fill:inherit;
				.transition;
			}

			use.search {
				fill:#a8a8a8;
				.transition;
			}
		}
	}

	&-item {
		position: relative;
		display: block;
		background-color: @hc;
		.transition;
		cursor: pointer;
		margin-top: 80px;
		&:hover{
			text-decoration: none;
		}

		&.small {
			margin-top: 0;

			.blog-item__img {
				height:160px;
			}

			.blog-item__txt {
				padding-left: 25px;
			    padding-right: 25px;
			    padding-bottom: 25px;
			}

			.blog-item__h {
				padding-top: 20px;
				padding-bottom: 35px;
				
				h3 {
					font-size: 16px;
				}
			}

			.blog-item__read {
				color:#D0D0D0;
				border-color:#939393;

				&:hover {
					color:@brc;
					border-color:@brc;
				}
			}
		}

		&.right-marg {
			margin-right: 35px;
		}

		&.left-marg {
			margin-left: 35px;
		}

		&:hover {
			box-shadow:0 0px 100px -1px rgba(0,0,0,.1);

			.blog-item__read {
				color:@brc;
				border-color:@brc;
				text-decoration: none;
			}
		}

		&__data {
			position: absolute;
			left:0px;
			top:0px;
			background-color: @brc;
			width: 71px;
			height:34px;

			p {
				.font-style(14px,@hc,700,37px,center);
			}
		}

		&__txt {
			padding-left: 40px;
			padding-right: 40px;
			padding-bottom: 40px;
			position: relative;
		}

		&__img {
			width: 100%;
			height: 320px;
		}

		&__h {
			padding-top: 35px;
			padding-bottom: 20px;

			h3 {
				.font-style(24px,@bc,700,1.4,left);
				letter-spacing: .2px;
			}
		}

		&__desc {
			padding-bottom: 25px;

			p {
				.font-style(16px,#a8a8a8,400,1.9,left);
				letter-spacing: .3px;
			}
		}

		&__read {
			display: inline-block;
			.font-style(13px,@bc,700,1.2,left);
			text-transform: uppercase;
			.transition;
			border-bottom:2px solid @bc;
			padding-bottom: 6px;

			&:hover {
				color:@brc;
				border-color:@brc;
				text-decoration: none;
			}
		}
	}

	&-pag {
		position: relative;
		float:right;
		padding-top: 60px;

		&-list {
			position: relative;
			float:right;
			padding-left: 0;
			list-style:none;
			padding-right: 1px;

			&__item {
				float:left;
				.transition;
				padding-bottom: 2px;
				border-bottom: 2px solid transparent;
				padding-left: 5px;
				padding-right: 5px;
				margin-left:5px;
				cursor:pointer;

				a {
					.font-style(18px,#959595,700,33px,center);
					.transition;

					&:hover, &:focus {
						text-decoration: none;
					}
				}

				&:hover, &.active {
					color:@bc;
					border-color:@brc;

					a {
						color:@bc;
					}

					i {
						border-color:@brc;
					}
				}

				&:first-child {
					padding-left:0;
					border-bottom:none;
					padding-right: 10px;
					padding-bottom: 0;
					margin-top: -1px;
				}

				&:last-child {
					padding-right: 1px;
					border-bottom:none;
					padding-left: 10px;
					padding-bottom: 0;
					margin-top: -1px;
				}

				i {
					border: solid @bc;
				    border-width: 0 2px 2px 0;
				    display: inline-block;
				    padding: 3.5px;
				    .transition;

				    &.right {
					    transform: rotate(-45deg);
					    -webkit-transform: rotate(-45deg);
					}

					&.left {
					    transform: rotate(135deg);
					    -webkit-transform: rotate(135deg);
					}
				}
			}
		}
	}
}


@media screen and (max-width: 1200px) {
    
    .blog-item {

    	&__img {
    		height:200px;
    	}

    	&__h {
			padding-top: 20px;
			padding-bottom: 15px;

    		h3 {
				font-size: 20px;
    		}	
    	}

    	&__desc {
			padding-bottom: 15px;

    		p {
    			font-size: 14px;
    		}
    	}

    	&__read {
    		font-size: 12px;
    	}

    	&.right-marg {
    		margin-right: 0;
    	}

    	&.left-marg {
    		margin-left: 0;
    	}
    }

}


@media screen and (max-width: 768px) {
	
	.blog {
		padding-top: 130px;
		padding-bottom: 70px;

		&-search {
			width: 100%;
		}
	}

	.blog-item {
		margin-top: 60px;

		&__desc {
			display: none;
		}

		&__img {
			height:160px;
		}

		&__txt {
			padding-left: 25px;
		    padding-right: 25px;
		    padding-bottom: 25px;
		}

		&__h {

			h3 {
				font-size: 16px;
			}
		}

		&__read {
			color:#D0D0D0;
			border-color:#939393;
		}
	}
}

@media screen and (max-width: 480px) {

	.blog {
		padding-top: 125px;
		padding-bottom: 50px;

		&-item {
			margin-top: 45px;
		}

		&-pag {
			float:none;
			padding-top: 45px;
		}

		&-pag-list {
			text-align: justify;
			float:none;
			padding-left: 6px;
			padding-right: 3px;

			&:before {
				content: '';
			    display: block;
			    width: 100%;
			    height: 0;
			}

			&:after {
				width: 100%;
			    height: 0;
			    visibility: hidden;
			    overflow: hidden;
			    content: '';
			    display: inline-block;
			}

			&__item {
				float:none;
				position: relative;
				display: inline-block;
				vertical-align: middle;
				margin-left: 0;

				&:first-child, &:last-child {
					margin-top: 0;
					top:-3px;
				}

				&:first-child {
					padding-right: 0;
				}

				&:last-child {
					padding-left: 0;
				}
			}
		}
	}
}