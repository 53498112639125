.benefits {
	position: relative;
	padding-top: 150px;

    &-img-1 {
        position: absolute !important;
        left:-31px !important;
        top: 40px !important;
        width: 60px;
        display: none;

        .img {
            width: 90px;
            height:90px;
            transform:rotate(30deg);

            img {
                width: 100%;
                height:100%;
            }
        }
    }

    &-img-2 {
        position: absolute !important;
        left:auto !important;
        right: -4px !important;
        top: 600px !important;
        display: none;
        width: 16px;
        height:16px;

        img {
            width: 100%;
            height:100%;
        }
    }

	&-block {
		position: relative;;
		.cf();

		&__img {
			width: 50px;
            height:50px;
			position: absolute;;
			left:10px;
			top:0;
		}

		&-txt {
			padding-left: 104px;

			&__h {
				padding-bottom: 21px;

				h2 {
					.font-style(24px,@hc,400,1.2,left);
					font-family: inherit;
				}
			}

			&__desc {

				p {
					.font-style(16px,@tc,300,1.8,left);
				}
                a {
                    color: #ec0f2a;
                    &:hover {
                        text-decoration: none;
                        border-bottom: 1px solid #ec0f2a;
                    }
                }
			}
		}
	}

	@media screen and (max-width: 1200px) {

        .benefits-block {

        	&-txt {
        		padding-left: 75px;
        	}
        }
    
    }

    @media screen and (max-width: 992px) {

        .benefits-block {

            &__img {
                position: relative;
                margin:0 auto;
                left:0;
                height:50px;
            }

            &-txt {
                padding-left: 0px;

                &__h {
                    padding-top: 35px;
                    padding-bottom: 35px;

                    h2 {
                        text-align: center;
                    }
                }

                &__desc {

                    p {
                        text-align: center;
                        max-width: 320px;
                        margin: 0 auto;

                    }
                }
            }
        }
    
    }

    @media screen and (max-width: 768px) {

        .benefits-block {
            padding-bottom: 60px;
        }
    }

    @media screen and (max-width: 480px) {

        .benefits-img-1,.benefits-img-2 {
            display: block;
        }
    }
}
