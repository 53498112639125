.offers {
	position: relative;
	padding-bottom: 191px;
	z-index: 0;

	&-h {
		position: relative;

		&__img {
			position: absolute;
			left:-25px;
			top:-25px;
			width: 100px;
			height:100px;
			z-index: 0;

			img {
				width: 100%;
				height:100%;
			}
		}

		h2 {
			.font-style(36px,@hc,400,1.2,left);
			font-family: inherit;
			position: relative;
			z-index: 1;
		}
	}


	&-layer {
		position:fixed;
		width: 100%;
		z-index: 101;
		height:100%;
		display: none;
		left:0;
		top:0;

		&__item {
			background-color: rgba(0,0,0,.7);
			width: 100%;
			height:100%;
			top:0;
			left:0;
		}
	}

	&-popup {
		position: fixed;
		top:50%;
		left:50%;
		transform:translateX(-50%) translateY(-50%);
		z-index: 30;

		&-close {
			position: absolute;
		    right: -16px;
		    top: -20px;
		    cursor: pointer;

		    &:hover {

		    	.offers-popup-close__line {
		    		background-color: @brc;
		    	}
		    }

		    &__wrap {
		    	position: relative;
		    	.cf();
		    }

		    &__line {
		    	float:left;
		    }

		    &__line {
		    	float:left;
		    	float: left;
			    transform: rotate(-45deg);
			    background-color: #fff;
			    width: 2px;
			    height: 20px;
			    position: relative;
			    .transition;

		    	&:last-child {
		    		transform: rotate(45deg);
    				left: -2px;
		    	}
		    }
		}

		&__wrap {
			position: relative;
			width: 740px;
			display: none;
			max-width: 100%;
		}

		&__img {
			width: 100%;
			height: 740px;
			text-align: center;

			img {
				max-height:100%;
				max-width: 100%;
				display: inline-block;
			}
		}

		&__desc {
			padding-top: 35px;
			
			p {
				text-align: center !important;
				.font-style(16px,@tcl,400,1.6,left);
			}
		}
	}

	&-bg-1 {
		position: absolute !important;
		top: 137px !important;
		left:auto !important;
    	right: 226px !important;
    	left:auto;
    	width: 130px;
    	height:130px;

    	img {
			width: 100%;
			height:100%;
		}
	}

	&-bg-2 {
		position: absolute !important;
		top: 440px !important;
		left:auto !important;
    	right: 436px !important;
    	width: 80px;
    	height: 80px;

    	img {
			width: 100%;
			height:100%;
		}
	}

	&-bg-3 {
		position: absolute;
		left:90% !important;
		top: 611px !important;
		width: 80px;
		height:80px;

		img {
    		width: 100%;
    		height:100%;
    	}
	}

	&-bg-4 {
		position: absolute;
		top: 520px !important;
    	left: -28px !important;
    	width: 100px;
		height:100px;

    	img {
    		width: 100%;
    		height:100%;
    	}
	}

	&-slider {
		position: relative;
		margin-top: 145px;
		display: block;

		.owl-nav {
			position: absolute;
			left:0;
			top:50%;
			display: block;
			.cf();
			width: 100%;
			height:32.5px;
			transform:translateY(-50%);
			z-index: -1;

			&.disabled {
				display: block;
			}

			.owl-prev {
				left:-77.5px;
				padding-right: 1.5px;
			}

			.owl-next {
				right:-77.5px;
				padding-left: 1.5px;
			}

			.owl-prev, .owl-next {
				height:32.5px;
				width: 32.5px;
				cursor:pointer;
				position: absolute;
				top:0;
				border-radius:50%;
				background-color: #222222;
				.transition;
				text-align: center;
				line-height: 32px;

				i {
					border: solid @hc;
				    border-width: 0 2px 2px 0;
				    display: inline-block;
				    padding: 3.5px;
				    .transition;

				    &.right {
					    transform: rotate(-45deg);
					    -webkit-transform: rotate(-45deg);
					    left:-2.5px;
					    top:1px;
					    position: relative;
					}

					&.left {
					    transform: rotate(135deg);
					    -webkit-transform: rotate(135deg);
					    right:-2.5px;
					    top:1px;
					    position: relative;
					}
				}

				&:hover {
					background-color:@brc;
				}
			}
		}

		&-item {
			position: relative;
			cursor:pointer;
			z-index: 1;

			&:hover .offers-slider-item__hov {
				opacity: 1;
			}

			&__img {
				position: relative;
				width: 100%;
				height: 288px;
				z-index: 1;
				overflow: hidden;
				display: block;
				background-position: center;
				border-radius: 24px;

				img {
					height:100%;
					width: 100%;
				}
			}

			&__hov {
				position: absolute;
				width: auto;
				height: auto;
				opacity: 0;
				left:0px;
				top:0;
				right: 0;
    			bottom: 0;
				.transition;
				background-color: rgba(0,0,0,.8);
				border-radius:24px;
			
			}

			&__scale {
				width: 40px;
				height: 40px;
				position: absolute;
				top:50%;
				left:50%;
				transform:translateX(-50%) translateY(-50%) ;
			}

			&__back {
				position: absolute;
				width: 500px;
				height: 260px;
				top:27px;
				left:27px;
				z-index: 0;

				img {
					height:100%;
					width: 100%;
				}
			}
		}
	}

	@media screen and (max-width: 1366px) {

        .offers-slider {
			padding-left: 50px;
			padding-right: 50px;

        	.owl-nav .owl-next {
				right:0px;
	        }

	        .owl-nav .owl-prev {
	        	left:0px;
	        }
        }
    
    }

    @media screen and (max-width: 1200px) {

		.offers-slider {
			padding-left: 0px;
			padding-right: 0px;

        	.owl-nav .owl-next {
				right:0px;
				display: none;
	        }

	        .owl-nav .owl-prev {
	        	left:0px;
	        	display: none;
	        }

	        &.owl-carousel .owl-stage-outer {
	            overflow: visible;
	        }
        }
	}

    @media screen and (max-width: 992px) {
		
        .offers-h {
			display: inline-block;

        	h2 {
				text-align: center;
        	}
        }
    
    }

    @media screen and (max-width: 480px) {
		
        .offers {

        	&__h h2 {
        		font-size: 28px;
        	}

        	&-bg-1, &-bg-2 {
        		display: none;
        	}

			&-slider {
				width: 100%;
				margin-top: 45px;

				&-item {
					
					&__img {
						height:170px;
					}

					&__hov {
						border-radius:15px;
					}
				}

				.owl-nav {

					.owl-prev {
						left:0;
					}

					.owl-next {
						right:0;
					}
				}
			}
        }
    
    }
}


@media screen and (max-width: 992px) {
		
        .offers {
        	text-align: center;
        	padding-bottom: 100px;
        	padding-top: 0px;

        	&-bg-3.layer {
        		display: none !important;
        	}
        }

        .offers-popup {
			
			&__img {
				height:auto;

				img {
					height:auto;
				}
			}
	
        	&__wrap {
        		width: 400px;
        	}

        	&__desc p {
        		font-size: 16px;
        	}
        }
    
    }

@media screen and (max-width: 480px) {
		
    .offers {
    	padding-top: 0;

    	.container {
    		padding-left: 30px;
    		padding-right: 30px;
    	}

    	.offers-slider-item__img {
    		height:205px;
    	}

    	.offers-slider-item__hov {
    		border-radius:9px;
    	}
    }

     .offers-popup {
			
		&__img {
			height:auto;

			img {
				height:auto;
			}
		}

    	&__wrap {
    		width: 250px;
    	}

    	&__desc p {
    		font-size: 15px;
    	}
    }

}