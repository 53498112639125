.popular {
	position: relative;
	padding-top: 150px;
	padding-bottom: 105px;
	z-index: 1;

	&-small-img {
		position: absolute;
		top:300px !important;
		left:-42px !important;
		width: 91px;
		height:91px;

		img {
			width: 100%;
			height:100%;
		}

		&-1 {
			top:850px !important;
			position: absolute;
			left:0 !important;
			z-index: 1;
			width: 16px;
			height:16px;

			img {
				width: 100%;
				height:100%;
			}
		}

		&-2 {
			position: absolute;
			top:850px !important;
			left:694px !important;
			z-index: 1;
			width: 60px;
    		height: 40px;

			img {
				width: 100%;
				height:100%;
			}
		}

		&-3 {
			position: absolute;
			top:830px !important;
			right:0 !important;
			left:auto !important;
			z-index: 1;
			width: 35px;
    		height: 35px;

			img {
				width: 100%;
				height:100%;
			}
		}
	}

	&-img-1 {
		position: absolute !important;
		top:20px !important;
		left:-55px !important;
		width: 71px;
		display: none;
	}

	&-bg {
		position: absolute;
		right: -124px;
	    top: -189px;
	    width: 310px;
	    height: 250px;
	    z-index: 0;

		&__img-1 {
			position: absolute;
			width: 76px;
			height:76px;
			top:120px !important;
			left:100px !important;
			z-index: 2;

			img {
				width: 100%;
				height:100%;
			}
		}

		&__img-2 {
			width: 16px;
			height:16px;
			position: absolute;
			top:40px !important;
			left:70% !important;
			z-index: 2;

			img {
				width: 100%;
				height:100%;
			}
		}

		&__layer {
			position: absolute;
			width: 100%;
			height:100%;
			top:0;
			right:0;
			z-index: 0;
			opacity: 0.03;
		}
	}

	&-wrap {
		position: relative;
		.cf();
		z-index: 1;
	}

	&-btns {
		float:right;
		font-family:'Nunito', sans-serif;
		padding-top: 12px;

		.btn-simple {
			margin-left: 15px;
		}

		.btn-primary {
			padding:0 50px;
		}

		.btn.icon-btn {
			padding:0 75px 0 30px;
			&:hover{
				border-color: #eb0029;
			}
		}
	}

	&__h {
		position: relative;
		float:left;

		&-img {
			position: absolute;
			width: 64px;
			left:-62px !important;
			top:30px !important;
			z-index: -1;
		}

		h2 {
			.font-style(36px,@hc,400,1.2,left);
			font-family: inherit;
		}
	}

	&-slider {
		position: relative;
		display: block;
		margin-top: 110px;

		&.owl-carousel .owl-stage-outer {
			overflow: visible;
		}

		.owl-item {
			opacity: 0;
			.transition;
			z-index: -20;

			&.active {
				opacity: 1;
				z-index: 1;

				.popular-slider-item {
					cursor:pointer;
				}
			}
		}

		.owl-nav {
			pointer-events: none;
			position: absolute;
			left:0;
			top: 55%;
			display: block;
			.cf();
			width: 100%;
			height:32.5px;
			transform:translateY(-67%);

			&.disabled {
				display: block;
			}

			.owl-prev {
				left:-77.5px;
				padding-right: 1.5px;
				pointer-events: all;
			}

			.owl-next {
				right:-77.5px;
				padding-left: 1.5px;
				pointer-events: all;
			}

			.owl-prev, .owl-next {
				height:32.5px;
				width: 32.5px;
				cursor:pointer;
				position: absolute;
				top:0;
				border-radius:50%;
				background-color: #222222;
				.transition;
				text-align: center;
				line-height: 32px;
				z-index:0;

				i {
					border: solid @hc;
				    border-width: 0 2px 2px 0;
				    display: inline-block;
				    padding: 3.5px;
				    .transition;

				    &.right {
					    transform: rotate(-45deg);
					    -webkit-transform: rotate(-45deg);
					    left:-2.5px;
					    top:1px;
					    position: relative;
					}

					&.left {
					    transform: rotate(135deg);
					    -webkit-transform: rotate(135deg);
					    right:-2.5px;
					    top:1px;
					    position: relative;
					}
				}

				&:hover {
					background-color:@brc;
				}
			}
		}

		&-item {
			position: relative;
			z-index: 1;
			padding-bottom: 75px;

			&:hover {
				z-index: 4;

				.popular-slider-item__hov {
					opacity: 1;

					.btn {
						bottom:27px;
						opacity: 1;
					}
				}

				&:before {
					opacity: 1;
				}
			}

			&__hov {
				opacity: 0;
				position: absolute;
				width: 120%;
				height: 110%;
				left:50%;
				top:50%;
				.transition;
				z-index: 1;
				border-radius:10px;
				background-color: #111;
				transform:translateX(-50%) translateY(-50%);
				box-shadow:0 0 100px -1px rgba(0,0,0,.4);

				.btn {
					position: absolute;
					left:50%;
					bottom:-30px;
					transform:translateX(-50%);
					width: 180px;
					z-index: 4;
					opacity: 0;
				}
			}

			&:before {
				content:"";
				position: absolute;
				background-color: rgba(9,9,9,.1);
				width: auto;
				height: auto;
				top:-66px;
				left:-66px;
				right:-66px;
				bottom:-66px;
				border-radius:90px;
				z-index: 1;
				.transition;
				opacity: 0;
			} 

			&-picture {
				margin:0 auto;
				width: 145px;
    			height: 145px;
				position: relative;
				z-index: 5;

				&__img {
					width: 100%;
					position: absolute;
					bottom:0;
					left:0;
				}
			}

			&__h {
				position: relative;
				padding-top: 45px;
				padding-bottom: 21px;
				z-index: 2;

				h2 {
					.font-style(24px,@hc,400,1.2,center);

					span {
						color:@brc;
					}
				}
			}

			&__desc {
				position: relative;
				padding-bottom: 25px;
				z-index: 5;

				p {
					.font-style(16px,@tc,300,1.6,center);
				}
			}

			&__price {
				position: relative;
				z-index: 2;

				p {
					.font-style(18px,@hc,700,1.2,center);
				}
			}
		}
	}

	@media screen and (max-width: 1366px) {

        .popular-slider {
			padding-left: 50px;
			padding-right: 50px;

        	.owl-nav .owl-next {
				right:0px;
	        }

	        .owl-nav .owl-prev {
	        	left:0px;
	        }

        }

        .popular-slider-item-picture {
    		width: 110px;
    		height: 110px;
    	}
    
    }

    @media screen and (max-width: 992px) {

    	.popular {

    		&-wrap {
    			padding-bottom: 40px;
    		}
			
			&-slider {
				width: 700px;
				margin:0 auto;

				.owl-nav {

					.owl-prev {
						left:-20px;
					}

					.owl-next {
						right:-20px;
					}
				}
			}

    		&-bg__img, &-bg__layer {
    			display: none;
    		}

    		&__h {
    			float:none;

    			&-img {
    				display: none;
    			}

    			h2 {
    				text-align: center;
    			}
    		}

    		&-btns {
    			float:none;
    			text-align: center;
    			padding-top: 55px;
    		}
    	}
    }

    @media screen and (max-width: 768px) {

    	.popular .popular-wrap {
    		padding-bottom: 60px;
    	}

		.popular-slider {
			width: 400px;

			&-item-picture {
				width: 145px;
				height: 145px;
			}

			&-item {
				padding-bottom: 0;

				&:before {
					display: none;
				}

				&:hover {

					.popular-slider-item__hov .btn {
						bottom:0;
					}
				}

				&__hov {
					transform:translateX(0%) translateY(0%);
					position: relative;
					left:0;
					top:0;
					width: 100%;
					height:auto;
					padding-top: 25px;
					opacity: 1;
					text-align: center;
					background-color:transparent;
					box-shadow:none;

					.btn {
						transform:translateX(0%) translateY(0%);
						left:0;
						bottom:0;
						position: relative;
						opacity: 1;
					}
				}
			}
		}
	}

	@media screen and (max-width: 480px) {

		.popular {
			padding-top: 50px;
			padding-bottom: 50px;

			&__h h2 {
				font-size: 28px;
			}

			&-btns {

				.btn-primary {
					padding:0 30px;
				}

				.icon-btn {
					padding:0 40px 0 15px;

					.icon-svg {
						width: 18px;
						right:15px;
					}
				}
			}

			&-slider {
				width: 100%;

				.owl-nav {

					.owl-prev {
						left:0;
					}

					.owl-next {
						right:0;
					}
				}
			}
		}
	}
}


@media screen and (max-width: 992px) {

	.popular {
		padding-top: 100px;
		padding-bottom: 20px;

		&-slider-item__desc p {
			max-width: 200px;
			margin:0 auto;
		}
	}
}

@media screen and (max-width: 768px) {

	.popular {
		padding-bottom: 100px;
	}
}

@media screen and (max-width: 480px) {

	.popular {
		padding-top: 50px;

		&-img-1 {
			display: block;
		}
	}
}