
.popups {
	background-color: rgba(1,1,1,.7);
	position: fixed;
    height: 100vh;
    width: 100%;
    z-index: 100;
    display: none;

    ::-webkit-scrollbar {
        width: 4px;
    }

    ::-webkit-scrollbar-thumb {
         border-radius: 0px;
         background-color:@bc;
         background-clip: padding-box;

    }

    ::-webkit-scrollbar-track {
            background-color:#ecedee;
            width: 1px;
            background-clip: padding-box;
    }

    &.act {
    	display: block;
    }
}

.mob-choice {
	display: none;
	padding-bottom: 35px;

	&__h {
		padding-bottom: 20px;

		h3 {
			.font-style(16px,#535353,300,1.2,center);
			text-transform: uppercase;
		}
	}
}

.menu {
	position: relative;
	padding-top: 237px;
	padding-bottom: 110px;
	overflow: hidden;

	&-drop {
		position: relative;
		width: 270px;
		margin:0 auto;
		max-width: 100%;

		&__field {
			position: relative;
			cursor:pointer;

			&:before {
				content:"";
				position: absolute;
				width: 100%;
				height:100%;
				top:0;
				left:0;
				cursor:pointer;;
			}

			&.act {

				input {
					border-color:#252525;
					border-bottom-left-radius:0;
					border-bottom-right-radius:0;
					color:@brc;
				}
			}

			input {
				border:1px solid #3A3A3A;
				text-transform: uppercase;
				line-height: normal;
				font-size: 14px;
				.transition;
				outline: none;
				border-radius:30px;
				width: 100%;
				height:45px;
				padding-left: 25px;
				color:#3A3A3A;
				background-color:  #050607;
			}
		}

		&__arrow {
			border: solid #535353;
		    border-width: 0 2px 2px 0;
		    display: inline-block;
		    padding: 4px;
		    transform: rotate(45deg);
		    position: absolute;
		    top:17px;
		    right:20px;
		}

		&__wrap {
			position: relative;
			top:0;
			left:0;
			width: 100%;
			margin-top: -1px;
			display: none;

			&.act {
				display: block;
			}
		}

		&-list {
			padding-left: 0;
			border-bottom-left-radius:30px;
			border-bottom-right-radius:30px;
			border:1px solid #252525;
			border-top:none;
			list-style:none;

			&__item {
				position: relative;
				padding-left: 25px;
				border-top:1px solid #252525;
				padding-top: 15px;
				padding-bottom: 15px;

				&.act.has-sub {
					padding-top: 0;
					padding-bottom: 0;
					padding-left: 0;
					border:none;

					.menu-drop-list__href {
						display: none;
					}

					.menu-drop-sublist {
						display: block;
					}
				}

				a {
					.font-style(14px,#D4D4D5,300,1.2,center);
					text-transform: uppercase;
					.transition;

					&:hover, &:focus {
						text-decoration: none;
						color:@brc;
					}
				}
			}
		}

		&-sublist {
			padding-left: 0;
			display: none;
			list-style:none;

			&__item {
				position: relative;
				padding-left: 35px;
				padding-top: 15px;
				padding-bottom: 15px;
				border-top:1px solid #252525;

				a {
					.font-style(14px,#535353,300,1.2,center);
					.transition;

					&:hover, &:focus {
						text-decoration: none;
						color:#D4D4D5;
					}

					&.act {
						color:#D4D4D5;
					}
				}
			}
		}
	}

	&-popup {
		position: absolute;
		top:50%;
		left:50%;
		transform:translateX(-50%) translateY(-50%);
		background-color: #fff;

		&.act {
			display: block;
		}
		

		&-closen {
			position: absolute;
		    right: 20px;
		    top: 20px;
		    cursor: pointer;

		    &:hover {

		    	.menu-popup-close__line {
		    		background-color: #fff;
		    	}
		    }

		    &__wrap {
		    	position: relative;
		    	.cf();
		    }

		    &__line {
		    	float:left;
		    }

		    &__line {
		    	float:left;
		    	float: left;
			    transform: rotate(-45deg);
			    background-color: #313131;
			    width: 2px;
			    height: 20px;
			    position: relative;
			    .transition;

		    	&:last-child {
		    		transform: rotate(45deg);
    				left: -2px;
		    	}
		    }
		}

		&__field {
			position: relative;
			width: 168px;
			padding-top: 50px;
			padding-bottom: 120px;
			margin:0 auto;

			input {
				border:none;
				border-bottom:2px solid #777777;
				padding-bottom: 25px;
				width: 100%;
				font-size: 72px;
				outline:none;
				text-align: center;
				line-height: normal;
				color:#777777;
				background-color:transparent;
				border-radius:none;

				.placeholder(#777777);
			}
		}

		&__icon {
			display: inline-block;
			width: 19px;
            height: 19px;
            vertical-align: middle;
            position: relative;

			svg path {
                fill:inherit;
                .transition;
            }

            use.foot-ico {
                fill:#747474;
            }
		}

		&__h {
			padding-bottom: 70px;

			h2 {
				.font-style(24px,@hc,300,1.2,center);
			}
		}

		&__btns {
			position: relative;
			text-align: center;

			&:after {
				display: inline-block;
				content:"";
				width: 0;
				height:100%;
			}

			.btn-primary {
				display: inline-block;
				vertical-align: middle;
				position: relative;
				padding:0 45px;
				margin-right: 10px;
			}

			.btn-simple {
				display: inline-block;
				vertical-align: middle;
				position: relative;
				margin-left: 10px;
				border-color:#6A6A6A;
				color:#6A6A6A;

				&:hover {
					color:#fff;
					border-color:#fff;
				}
			}
		}

		&__desc {
			padding-top: 90px;
			padding-bottom: 90px;
			position: relative;
			text-align: center;

			&:after {
				display: inline-block;
				content:"";
				width: 0;
				height:100%;
			}

			p {
				.font-style(16px,#747474,300,1.2,left);
				vertical-align: middle;
				display: inline-block;
				padding-left: 4px;
				position: relative;

				span {
					color:#fff;
					font-weight: 700;
				}
			}
		}

		&__wrap {
			position: relative;
			width: 750px;
			padding:80px;
			background-color: @bc;
		}

		&-list {
			position: relative;
			padding-left: 0;
			list-style:none;
			text-align: center;

			&:after {
				display: inline-block;
				content:"";
				width: 0;
				height:100%;
			}

			&__item {
				position: relative;
				display: inline-block;
				vertical-align: middle;

				a {
					.font-style(16px,@tc,300,1.2,left);
					text-transform: uppercase;
					float:left;
					display: block;
					border-bottom:2px solid transparent;
					padding-bottom: 2px;
					font-family: inherit;
					cursor: pointer;
					letter-spacing: 1px;

					&.active {
						color:@hc;
						border-bottom:2px solid @brc;
					}

					&:hover {
						text-decoration: none;
						color:@hc;
						border-bottom:2px solid @brc;
						.transition;
					}
				}

				span {
					padding-left: 15px;
					padding-right: 15px;
					float:left;
					color:@tc;
					font-family: inherit;
					font-size: 16px;
				}	
			}
		}
	}

	&.small-pad {
		padding-bottom: 120px;
	}

	&-bg-1 {
		position: absolute !important;
		top:88px !important;
		left:98% !important;
		width: 71px;
	}

	&-bg-2 {
		position: absolute;
		top:0 !important;
		left:auto !important;
		right:268px !important;
		width: 16px;
		height:16px;

		img {
			width: 100%;
			height:100%;
		}
	}

	&-bg-3 {
		position: absolute;
		top:auto !important;
		bottom:140px !important;
		left:auto !important;
		right:-25px !important;
		width: 60px;
		height:60px;

		img {
			width: 100%;
			height:100%;
			transform:rotate(25deg);
		}
	}

	&-bg-4 {
		position: absolute;
		top:auto !important;
		bottom:0px !important;
		left:136px !important;
		width: 16px;
		height:16px;

		img {
			width: 100%;
			height:100%;
		}
	}

	&-bg-5 {
		position: absolute;
		top:600px !important;
		left:-5px !important;
	}
}

.menu-item {
	position: relative;

	&__time {
		padding-top: 50px;
		padding-bottom: 50px;

		p {
			.font-style(18px,@tcl,300,1.2,left);

			span {
				color:@hc;
			}
		}
	}

	&-img {
		position: absolute !important;
		left:-60px !important;
		width: 60px;
		top:-10px !important;
		display: none;
		width: 100px;
		height:100px;

		img {
			width: 100%;
			height:100%;
		}
	}

	&-img-1 {
		position: absolute;
		left: -40px !important;
	    width: 60px;
	    top: 730px !important;
	    display: none;
	}

	&-choice {
		position: relative;
		.cf();
		padding-top: 10px;

		&__desc {

			p {
				.font-style(24px,@hc,300,1.2,left);
				font-family: inherit;
			}
		}

		&__list {
			display: block;
			margin-top: 30px;
			list-style: none;
			.clearfix();
			padding-left: 0;
		}

		&__itm {
			float:left;

			a {
				.font-style(18px,#555,300,1.2,left);
				text-transform: uppercase;
				float:left;
				display: block;
				border-bottom:2px solid transparent;
				padding-bottom: 2px;
				font-family: inherit;
				cursor: pointer;
				letter-spacing: 1px;

				&.active {
					color:@hc;
					border-bottom:2px solid @brc;
				}

				&:hover {
					text-decoration: none;
					color:@hc;
					border-bottom:2px solid @brc;
					.transition;
				}
			}

			span {
				padding-left: 13px;
				padding-right: 13px;
				float:left;
				color:#555;
				font-family: inherit;
				font-size: 19px;
			}	
		}
	}

	&__h {
		padding-bottom: 45px;
		display: none;

		h2 {
			.font-style(30px,@hc,300,1.2,center);
		}
	}

	&-head {
		.cf();
		padding-bottom: 90px;

		&__desc {
			padding-top: 12px;
			float:left;

			p {
				.font-style(18px,@hc,300,1.2,left);
			}
		}

		.btn {
			float:left;
			margin-left: 25px;
			opacity: .5;
			padding: 0;
			width: 158px;
			height:44px;
			line-height: 44px;

			&:hover {
				border-color:@brc;
				opacity: 1;
			}

			&.active {
				opacity: 1;
				cursor:pointer;

				&:hover {
					border-color:@brc;
				}
			}
		}
	}

	&-take {
		padding-bottom: 75px;
		margin-bottom: 50px;
		position: relative;
		cursor: pointer;
		.transition;

		&:before {
			content:"";
			position: absolute;
			background-color: rgba(9,9,9,.1);
			width: auto;
			height: auto;
			top:-66px;
			left:-66px;
			right:-66px;
			bottom:-66px;
			border-radius:90px;
			z-index: 1;
			.transition;
			opacity: 0;
		} 
		 
		&:hover {
			z-index: 4;

			.menu-item-take__hov {
				opacity: 1;

				.btn {
					bottom:27px;
					opacity: 1;
				}
			}

			&:before {
				opacity: 1;
			}
		}

		&__hov {
			opacity: 0;
			position: absolute;
			width: 110%;
			height: 110%;
			left:50%;
			top:50%;
			.transition;
			z-index: 1;
			border-radius:10px;
			background-color: #0c0c0c;
			transform:translateX(-50%) translateY(-50%);
			box-shadow:0 0 100px -1px rgba(0,0,0,.4);

			.btn {
				position: absolute;
				left:50%;
				bottom:-30px;
				transform:translateX(-50%);
				width: 220px;
				z-index: 4;
				opacity: 0;
			}
		}

		&__pict {
			height: 165px;
    		width: 160px;
			text-align: center;
			margin:0 auto;
			position: relative;
			z-index: 5;
		}

		&__img {
			width: 100%;
			position: absolute;
			top:50%;
			transform:translateY(-50%);
		}

		&__h {
			padding-bottom: 17px;
			padding-top: 15px;
			z-index: 2;
			position: relative;

			h3 {
				.font-style(24px,@hc,400,1.2,center);
				font-family: inherit;

				span {
					color:@brc;
				}
			}
		}

		&__desc {
			padding-bottom: 17px;
			position: relative;
			z-index: 5;

			p {
				.font-style(16px,@tc,300,1.6,center);
			}
		}

		&__price {
			position: relative;
			z-index: 2;

			p {
				.font-style(18px,@hc,700,1.2,center);
			}
		}
	}

	

	&-block {
		position: relative;
		padding-bottom: 45px;
		margin-bottom: 45px;
		border-bottom:1px solid #242424;
		padding-left: 15px;

		&__wrap {
			position: relative;
			width: 100%;
			text-align: left;

			&:after {
				display: inline-block;
				content:"";
				position: absolute;
				width:0%;
				height:100%;
				vertical-align: middle;
			}
		}

		&__img {
			width: 112px;
			display: inline-block;
			position: relative;
			vertical-align: middle;
		}

		&__txt {
			display: inline-block;
			position: relative;
			vertical-align: middle;
			padding-left: 70px;
			max-width: 500px;
			ol{
				padding-left: 0;
				.font-style(16px,#737373,300,26px,left);
				font-family: 'Nunito', sans-serif;
			}
		}

		&__h {
			padding-bottom: 17px;

			h3 {
				.font-style(24px,@hc,400,1.2,left);
				font-family: inherit;

				span {
					color: #eb0029;
				}
			}
		}

		&__desc {

			p {
				.font-style(16px,#737373,300,26px,left);
				font-family: 'Nunito', sans-serif;
			}
		}

		&__price {
			position: absolute;
			top:50%;
			right:0;
			transform:translateY(-50%);

			p {
				.font-style(18px,@hc,700,1.2,right);
			}
		}
	}
}

@media screen and (max-width: 1200px) { 

	.menu-item-take__hov .btn {
		width: 180px;
		padding:0;
	}

}

@media screen and (max-width: 992px) { 

	.menu {
		padding-bottom: 120px;

		&-bg-1, &-bg-2, &-bg-3, &-bg-4, &-bg-5 {
			display: none;
		}

		&-item-head.pd-sm {
			padding-bottom: 40px;
		}

		&-item-choice {

			&__desc {

				p {
					font-size: 18px;
				}
			}

			&__list {
				padding-top: 4px;
			}

			&__itm {

				a {
					font-size: 16px;
				}

				span {
					font-size: 18px;
				}
			}
		}
	}

	.menu-item-block {

		&__img {
			width: 90px;
		}

		&__h h3 {
			font-size: 20px;
		}
		
		&__txt {
			width: 68%;
			padding-left: 30px;
			padding-right: 30px;
		}
	}
}

@media screen and (max-width: 768px) { 

	.mob-choice {
		display: block;
	}

	.menu {
		padding-top: 140px;

		&-item__h {
			display: block;
		}

		&-item-choice {
			
			&__desc {
				float:none;

				p {
					text-align: center;
				}
			}

			&__list {
				text-align: center;
				padding-left: 0;
				width: 100%;
				padding-top: 30px;
			}

			&__itm {
				display: inline-block;
				float:none;
				.cf();

				span {
					padding-right: 10px;
				}
			}
		} 

		.col-md-4 {

			&:last-child .menu-item-take {
				padding-bottom: 0;
			}
		}

		&-item-take {
			padding-bottom: 60px;

			&:before {
				display: none;
			}

			&:hover {

				.menu-item-take__hov .btn {
					bottom:0;
				}
			}

			&__desc {
				width: 210px;
				margin:0 auto;
			}

			&__hov {
				transform:translateX(0%) translateY(0%);
				position: relative;
				left:0;
				top:0;
				width: 100%;
				height:auto;
				padding-top: 45px;
				opacity: 1;
				text-align: center;
				background-color:transparent;

				.btn {
					transform:translateX(0%) translateY(0%);
					left:0;
					bottom:0;
					position: relative;
					opacity: 1;
				}
			}
		}
	}
	
	.menu-item-head {
		text-align: center;

		&__desc {
			display: none;
		}

		.btn {
			display: inline-block;
			float:none;
			// width: 120px;
			&:nth-child(2) {
				margin-bottom: 20px;
			}
			&:nth-child(3) {
				margin-left: 0;
			}

			&.frst {
				margin-left: 0;
				margin-right: 12.5px;
			}

			&.lst {
				margin-left: 12.5px;
			}
		}
	}
}


@media screen and (max-width: 480px) {

	.menu {
		padding-bottom: 35px;

		&-item-img, &-item-img-1 {
			display: block;
		}
	} 
	
	.menu-item-block {
		text-align: center;

		&__img {
			margin:0 auto;
			margin-bottom: 35px;
			display: block;
		}

		&__txt {
			padding: 0;
			width: 100%;
		}

		&__h {
			display: block;

			h3 {
				text-align: center;
				font-size: 18px;
			}
		}

		&__desc {
			padding-bottom: 17px;
			width: 210px;
			margin:0 auto;
			text-align: center;
			p {
				text-align: center;
			}
		}

		&__price {
			position: relative;
			top:0;
			transform:translateY(0);

			p {
				text-align: center;
			}
		}
	}

	.menu-item-take {

		&__desc {
			padding-bottom: 17px;
		}

		&__h {

			h3 {
				font-size: 18px;
			}
		}
	}
}


@media screen and (max-width: 768px) {

	.popups {
		overflow-y:scroll;
	}

	.menu-popup {
		width: 100%;
		left:0;
		top:0;
		transform: translateX(0%) translateY(0%);

		::-webkit-scrollbar {
            width: 4px;
        }

        ::-webkit-scrollbar-thumb {
             border-radius: 0px;
             background-color:@bc;
             background-clip: padding-box;

        }

        ::-webkit-scrollbar-track {
                background-color:#ecedee;
                width: 1px;
                background-clip: padding-box;
        }

		&__wrap {
			padding:30px;
			height:100%;

			&.scroll {
				overflow-y:scroll;
			}

			::-webkit-scrollbar {
	            width: 4px;
	        }

	        ::-webkit-scrollbar-thumb {
	             border-radius: 0px;
	             background-color:@bc;
	             background-clip: padding-box;

	        }

	        ::-webkit-scrollbar-track {
	                background-color:#ecedee;
	                width: 1px;
	                background-clip: padding-box;
	        }
		}

		&__field {
			width: 80px;
			padding-top: 30px;
			padding-bottom: 100px;

			input {
				font-size: 50px;
				padding-bottom: 15px;
			}
		}

		&__h {
			padding-bottom: 50px;

			h2 {
				line-height: 1.6;
			}
		} 

		&__wrap {
			width: 100%;
		}

		&__desc {
			padding-top: 50px;
			padding-bottom: 50px;
		}

		&__btns {

			.btn-simple {
				display: block;
				padding:0;
				height:60px;
				line-height: 60px;
			    width: 270px;
				margin: 0 auto;
				border-radius:50px;
			}

			.btn-primary {
				display: block;
				padding:0;
				height:60px;
				line-height: 60px;
				width: 270px;
    			margin: 0 auto;
    			border-radius:50px;
    			margin-bottom: 20px;
			}
		}

		&-list {
			
			&__item {
				width: 100%;
				padding-bottom: 40px;
				text-align: center;
				display: block;

				&:last-child {
					padding-bottom: 0;
				}

				span {
					display: none;
				}

				a {
					display: inline-block;
					float:none;
				}
			}
		}
	}

	.menu-item-choice, .menu-item__time {
		display: none;
	}
	
}
.last-item {
	border-bottom:none;
	margin-bottom: 0;
}
.row.fix {
  display: flex;
  flex-wrap: wrap;
}
.page{
	color: #737373;
	font-family: 'Nunito', sans-serif;
	font-size: 16px;
	font-weight: 300;
	line-height: 26px;
}
.item-sortable{
	@media ( max-width: 480px) {
		width: 100%;
	}
}
#collection {
	padding-bottom: 0px !important;
}
.hover-zoom {
	transition: all 0.3s ease;
	transform: scale(1);
}
.hover-zoom:hover {
	transform: scale(2);
}