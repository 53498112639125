///***HEADER****///

.main-header {
	position: absolute;
	width: 100%;
	padding-top: 40px;
	.transition;

	&.bgBlack {
		background-color: @bc;
		padding-top: 20px;
		padding-bottom: 32px;
	}

	&.scrolled {
		background-color: #050607;
		position: fixed;
		padding-top: 20px;
		padding-bottom: 32px;
	}

	&__logo {
		height:60px;
		width: 140px;
		display: block;
	}

	.show-sm {
		display: none;
	}

	&__hamb {
		height:50px;
		width: 50px;

		&:hover path {
			fill:@brc;
		}

		path {
			fill:@hc;
			.transition;
		}
	}

	&-menu{
		position: relative;
		list-style:none;
		padding-top: 28px;
		padding-left: 0;
		.cf();

		li {
			position: relative;
			cursor:pointer;
			float: left;
			padding-left: 40px;

			&:hover, &.active {

				a {
					color:@hc;
				}

				a:before {
					display: block;
					opacity: 1;
					width: auto;
				}
			}

			a {
				.font-style(16px,@tc,600,1.2,left);
				display: inline-block;
				text-decoration: none;
				.transition;
				position: relative;

				&:before {
					content:"";
					height: 2px;
					width: 100%;
					background: #eb0029;
					position: absolute;
					bottom: -7px;
    				left: 0;
					opacity: 0;
					right: 0;
					.transition;
				}
			}
		}
	}

	&-bag {
		position: relative;
		width: 26px;
		margin-top: 25px;
		float:right;
		display: block;

		&:hover {
			z-index: 1;

			.main-header-bag__img {

				svg path {
					fill:inherit;
				}

				use.shop-bag {
					fill:@brc;
				}
			}

			.main-header-bag-numb {
				background-color: #fff;

				p {
					color:@brc;
				}
			}
		}

		&-numb {
			border-radius: 50%;
		    height: 15px;
		    width: 15px;
		    background-color: @brc;
		    text-align: center;
		    position: absolute;
		    top: -3px;
		    right: -4px;
		    cursor:pointer;
		    .transition;

		    p {
		    	.font-style(8px,@hc,700,14.5px,center);
		    }
		}

		&__img {
			width: 20px;
			cursor:pointer;
			height:20px;

			svg path {
				fill:inherit;
			}

			use.shop-bag {
				fill:@hc;
				.transition;
			}

			img {
				width: 100%;
			}
		}
	}

	&-lang {
		position: relative;
		float:right;
		margin-top: 30px;
		margin-right: 30px;

		&:hover {
			cursor:pointer;

			.main-header-lang__wrap {
				top:0;
			}
		}

		&__desc {
			float:left;
			position: relative;
			padding-right: 12.5px;

			&:after {
				content:"";
				width: 0;
				height: 0;
				border-left: 3px solid transparent;
				border-right:3px solid transparent;
				border-top: 6px solid @hc;
				position: absolute;
				right:1px;
				top:5px;
			}

			p {
				.font-style(14px,@hc,600,1.2,center);
				text-transform: uppercase;
				letter-spacing: 2px;
			}
		}

		&__cont {
			@include cf();
			position: relative;
		}

		&__menu {
			background-color:@bc;
			border-radius:14px;
			list-style:none;
			border:1px solid @tc;
			padding:0;
		}

		&__item {
			.transition;
			border-bottom:1px solid @tc;
			cursor:pointer;

			&:hover {
				background-color: @brc;

				a {
					color:@hc;
				}
			}

			&:first-child {
				border-top-left-radius:14px;
				border-top-right-radius:14px;
			}

			&:last-child {
				border-bottom-left-radius:14px;
				border-bottom-right-radius:14px;
				border-bottom:none;
			}

			a {
				.font-style(14px,@tc,600,1.2,center);
				.transition;
				text-transform: uppercase;
				text-decoration: none;
				display: block;
				padding:12px;
			}
		}

		&__wrap {
			position: absolute;
			right:-8.5px;
			padding-top: 35px;
			top:-600px;
			z-index: 10;
		}
	}

	@media screen and (max-width: 1366px) {

		.main-header-menu {

			li {
				padding-left: 20px;

				a {
					&:before {
						// left:15px;
					}
				}
			}
		}
	
	}

	@media screen and (max-width: 992px) {

	    .hide-sm {
	    	display: none;
	    }

	    .show-sm {
			display: block;
		}

	    .main-header {
			
			&__logo {
				position: absolute;
				left:50%;
				top:50%;
				transform:translateX(-50%) translateY(-50%);
			}

	    	&__hamb {
	    		position: absolute;
				left:20px;
				top:50%;
				transform:translateY(-50%);
	    		margin-top: 0;

	    		svg path {
	    			fill:inherit;
	    		}

	    		use.hamburger {
				    fill: @hc;
				    .transition;
				}

				&:hover {
					use.hamburger {
					    fill: @brc;
					}
				}
	    	}

	    	&-lang {
	    		display: none;
	    	}
	    }

	}

	@media screen and (max-width: 480px) {
		
		.main-header {

			&__hamb {
				height:20px;
				width: 30px;
			}

			&__logo {
				height:40px;
				width: 80px;
			}
		}

	}
}


@media screen and (max-width: 1366px) {
	
    .container {
    	padding-left: 15px;
    	padding-right: 15px;
    }

}

@media screen and (max-width: 992px) {

	.main-header {
		padding-top:0px;
		padding-bottom:0px;
		height:50px;

		&.scrolled {
			padding-bottom: 0;
			padding-top: 0;
			height:80px;
		}

		&.bgBlack {
		 	padding-top:0px;
			padding-bottom:0px;
			height:90px;
		}

		.container, .row, .col-xs-12 {
			height:100%;
		}

		&-bag {
    		margin-top: 0;
    		float:none;
    		position: absolute;
			right:35px;
			top:50%;
			transform:translateY(-50%);

    		&-numb {
    			height:25px;
    			width: 25px;
    			top:0;
    			right:-25px;

    			p {
    				font-size: 14px;
    				line-height: 25px;
    			}
    		}

    		&__img {
    			width: 37px;
    			height:37px;

    			img {
    				width: 100%;
    			}
    		}
    	}
	}
}


@media screen and (max-width: 768px) {

	.main-header {
		background-color: @bc;
	}

}

@media screen and (max-width: 480px) {

	.main-header {

		.main-header-bag {
			margin-right: 0;
			right:30px;
		}

		.main-header-bag__img {
			width: 25px;
			height:30px;
		}

		.main-header-bag-numb {
			height: 20px;
			width: 20px;
			right:-8px;

			p {
				font-size: 10px;
				line-height: 20px;
			}
		}
	}

}

body.act {
	overflow: hidden;
}


.mob-header {
	position: fixed;
	height:100vh;
	width: auto;
	background-color: #080808;
	left:0;
	top:0;
	right:50px;
	z-index: 100;
	overflow-y:scroll;
	display: none;
	padding-bottom: 100px;

	::-webkit-scrollbar {
        width: 4px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background-clip: padding-box;
        background-color:#ecedee;

    }

    ::-webkit-scrollbar-track {
        background-color:@bc;
        width: 1px;
        background-clip: padding-box;
    }

	&.act {
		display: block;
	}

	&-top {
		position: relative;
		height:80px;
		border-bottom:1px solid #292929;
		.cf();

		&__left {
			width: 70%;
			border-right:1px solid #292929;
			height:100%;
			position: relative;
			float:left;
		}

	}

	&__icon {
		width: 30%;
		float:left;
		position: relative;
		height:100%;
	}

	&-lang {
		position: absolute;
		padding-left: 0;
		left: 50%;
	    top: 50%;
	    cursor: pointer;
	    transform:translateX(-50%) translateY(-50%);


		&__item {
			.font-style(20px,#5D5D5D,400,1,center);
			display: inline-block;
			position: relative;
			vertical-align: middle;
			.transition;
			margin-left: 30px;

			&:first-child {
				margin-left: 0px;
			}

			&:hover, &.active {
				color:@hc;
			}
		}
	}

	&-close {
		position: absolute;
	    left: 50%;
	    top: 50%;
	    cursor: pointer;
	    transform:translateX(-50%) translateY(-50%);

	    &:hover {

	    	.mob-header-close__line {
				background-color: @brc;
	    	}
	    }

	    &__wrap {
	    	position: relative;
	    	.cf();
	    }

	    &__line {
	    	float: left;
		    transform: rotate(-45deg);
		    background-color: #fff;
		    width: 3px;
		    height: 25px;
		    position: relative;
		    .transition;

		    &:last-child {
		    	transform: rotate(45deg);
    			left: -3px;
		    }
	    }
	}

	&-menu {
		position: relative;
		list-style: none;
		padding-left: 0;

		&__arrow {
			border: solid #fff;
		    border-width: 0 2px 2px 0;
		    display: inline-block;
		    padding: 6px;
		    transform: rotate(45deg);
		    position: absolute;
		    top:43px;
		    right:40px;
		}

		li {
			border-bottom:1px solid #292929;
			position: relative;

			a {
				display: block;
				padding-top: 20px;
				padding-bottom: 20px;
				.font-style(20px,#fff,400,1,left);
				.transition;
				padding-left: 40px;
				text-decoration: none;

				&:hover, &.active, &:hover, &:focus {
					color:@brc;
					text-decoration: none;
				}
			}
		}
	}

	&-submenu {
		position: relative;
		list-style: none;
		padding-left: 0;
		display: none;

		&.active {
			display: block;
		}

		li {
			border-bottom:1px solid #292929;
			position: relative;

			&:first-child {
				border-top:1px solid #292929;
			}

			&:last-child {
				border-bottom:none;
			}

			a {
				display: block;
				padding-top: 40px;
				padding-bottom: 40px;
				.font-style(20px,#777777,400,1,left);
				.transition;
				padding-left: 80px;
				text-decoration: none;

				&:hover, &.active, &:hover, &:focus {
					color:#fff;
					text-decoration: none;
				}
			}
		}
	}
}
.mob-header-lang__item {
	a {
		color: inherit;
		font-size: inherit;
		text-decoration: none;
		&:hover {
			color: inherit;
			font-size: inherit;
			text-decoration: none;
		}
	}
	
}

@media screen and (max-width: 480px) {

	.main-header {

		&.bgBlack {
			box-shadow: 0 10px 50px -3px rgba(0, 0, 0, 0.3);
		}
	}

	.mob-header-lang {

		&__item {
			margin-left: 15px;
			font-size: 21px;
		}
	}

	.mob-header-close {
		
		&__line {
    		height: 23px;
		}
	}

	.mob-header-menu {
		
		&__link {
			padding-top: 30px;
			padding-bottom: 30px;
			padding-left: 30px;
			font-size: 20px;
		}
	}

	.mob-header-submenu {

		a {
			padding-top: 30px;
			padding-bottom: 30px;
			padding-left: 30px;
			padding-left: 60px;
			font-size: 20px;
		}
	}

	.mob-header-menu__arrow {
		border-width: 0 2px 2px 0;
		padding:6px;
		top:20px;
	}

	.container {
	    padding-left: 25px;
	    padding-right: 25px;
	}

}

.head-card {
	position: fixed;
	top:0px;
	right:-335px;
	width: 335px;
	z-index: 10;
	.transition;
	background-color: #101010;
	// height:100vh;
	
	height: 100%;
	box-shadow: 0px 0px 80px -3px rgba(0,0,0,.8);
    transition: all 450ms cubic-bezier(.15,.35,.86,.82);

	&.act {
		right:0;
	}

	&__arrow {
		position: absolute;
		top:50%;
		transform:translateY(-50%);
		cursor:pointer;
		right:20px;

		&:hover i {
			border-color:@brc;
		}

		i {
			border: solid @tcl;
		    border-width: 0 2px 2px 0;
		    display: inline-block;
		    padding: 4px;
		    .transition;
		    transform: rotate(-45deg);
		}
	}

	&-closen {
		position: absolute;
	    right: 20px;
	    top: 20px;
	    cursor: pointer;
	    display: none;

	    &:hover {

	    	.head-card-closen__line {
	    		background-color: #fff;
	    	}
	    }

	    &__wrap {
	    	position: relative;
	    	.cf();
	    	position: absolute;
		    left: 0;
		    bottom: 0;
		    right: 0;
		    top: 0;
		    margin: auto;
	    }

	    &__line {
	    	float:left;
	    	position: absolute;
		    left: 0;
		    bottom: 0;
		    right: 0;
		    top: 0;
		    margin: auto;
	    }

	    &__line {
	    	position: absolute;
		    left: 0;
		    bottom: 0;
		    right: 0;
		    top: 0;
		    margin: auto;
	    	float:left;
	    	float: left;
		    transform: rotate(-45deg);
		    background-color: #ccc;
		    width: 2px;
		    height: 20px;
		    // position: relative;
		    .transition;

	    	&:last-child {
	    		transform: rotate(45deg);
				// left: -2px;
	    	}
	    }
	}


	.main-header-bag {
		float:left;
		position: relative;
		margin:0;
	}

	&__bottom {
		position: absolute;
		bottom:0;
		left:0;
		width: 100%;
	}

	::-webkit-scrollbar {
        width: 4px;
    }

    ::-webkit-scrollbar-thumb {
         border-radius: 0px;
         background-clip: padding-box;
            background-color:#ecedee;

    }

    ::-webkit-scrollbar-track {
         background-color:@bc;
            width: 1px;
            background-clip: padding-box;
    }

	&__left {
		background-color:#050607;
		padding-top: 48px;
    	padding-bottom: 64px;
		padding-left: 25px;
		position: relative;
	}

	&__h {
		float:left;
		padding-left: 25px;
		position: relative;

		h3 {
			.font-style(18px,@hc,700,1.2,left);
			text-transform: uppercase;
		}
	}

	&__desc {

		p {
			.font-style(18px,#2E2F31,700,1.2,left);
		}
	}

	&-close {
		position: absolute;
	    right: 12px;
		top: 12px;
	    cursor: pointer;
	    opacity: 0;
	    .transition;

	    &__wrap {
	    	position: relative;
	    	border-radius:50%;
	    	background-color: @brc;
	    	.cf();
	    	width: 20px;
		    height: 20px;
		    padding-left: 9px;
		    padding-top: 2px;
		    cursor:pointer;
	    }

	    &__line {
	    	float: left;
		    transform: rotate(-45deg);
		    background-color: #fff;
		    width: 1px;
		    height: 15px;
		    position: relative;

		    &:last-child {
		    	transform: rotate(45deg);
				left: -1px;
		    }
	    }
	}

	&-menu {
		position: relative;
		width: 100%;
		background-color: #101010;
		list-style:none;
		padding-left: 0;
		max-height: calc(~'100vh - 241px');
		pointer-events: all;
		overflow-y: scroll;
		border-bottom:1px solid #1B1919;

		&__item {
			position: relative;
			.transition;
			padding-top: 25px;
			padding-bottom: 25px;
			padding-left: 30px;
			cursor:pointer;
			border-bottom:1px solid #1B1919;

			&:after {
				content:"";
				display: inline-block;
				width: 0;
				height:100%;
			}

			&:hover {
				background-color: #050607;

				.head-card-close {
					opacity: 1;
				}
			}

			&:last-child {
				border:none;
			}
		}

		&__img {
			display: inline-block;
			position: relative;
			vertical-align: middle;

			.img {
				width: 65px;
			}
		}

		&__txt {
			display: inline-block;
			position: relative;
			vertical-align: middle;
			padding-left: 30px;

			h3 {
				.font-style(16px,#fff,300,1.2,left);
				padding-bottom: 15px;
			}

			p {
				.font-style(16px,#fff,700,1.2,left);

				span {
					font-weight: 300;
				}
			}
		}
	}

	&-count {
		position: relative;
		padding-top: 25px;
		padding-bottom: 25px;
		background-color: #101010;
		.cf();
		padding-left: 30px;
		padding-right: 30px;

		&__h {
			float:left;

			h3 {
				.font-style(16px,#fff,700,1.2,left);
				text-transform: uppercase;
			}
		}

		&__desc {
			float:right;

			p {
				.font-style(16px,#fff,700,1.2,right);
			}
		}
	}

	.btn-primary {
		width: 100%;
		height:60px;
		line-height: 60px;
		border-radius:0;
		display: block;
	}
}

@media (max-width: 992px) {
	.main-header__logo {
	    height: 40px;
	    width: 100px;
	}
	.head-card {
		width: 270px;

		&__h {
			float:left;
			padding-left: 0;

			h3 {
				font-weight: 300;
			}
		}

		&__arrow {
			display: none;
		}

		&__left {
			padding-top: 31px;
    		padding-bottom: 48px;
		    padding-left: 15px;
		}

		&__desc {
			float:left;
			padding-left: 10px;

			p {
				font-weight: 300;
			}
		}

		&-menu {

			&__item {
				padding-left: 15px;
				padding-top: 15px;
				padding-bottom: 15px;
			}

			&__img {
				width: 50px;
			}
		}

		&-close {
			display: block;
		}

		.main-header-bag {
			display: none;
		}

		&-closen {
			display: block;
			top:32px;
			width: 20px;
    		height: 20px;
		}
	}

}


@media screen and (max-width: 992px) {

	.head-card {
		
		&-menu {
			position: absolute;
			top:80px;
			width: 100%;
			bottom:151px;
			max-height:none;
			border:none;

			&__item:last-child {
				border-bottom:1px solid #1B1919;
			}
		}
	}

}
.col-md-4 {
	width: 33.2%;
}
.menu-item-block__wrap .menu-item-block__desc p{
	font-size: 16px;
    color: #b1b1b1;
    font-weight: 300;
    line-height: 1.6;
}