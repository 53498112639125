.sidebar {
	padding-top: 11px;

	&-menu {
		padding-left: 0;
		list-style:none;
	
		&__item {
			position: relative;
			.font-style(18px,@hc,300,1.2,left);
			padding-bottom: 25px;
			padding-left: 37px;
			.transition;

			a {
				.font-style(18px,@hc,300,1.2,left);
				.transition;

				&:hover, &:focus {
					text-decoration: none;
					color:@brc;
				}
			}

			.sub__link:focus {
				color:@hc;
			}

			.sub__link:hover {
				color:@brc;
			}

			&.non-col {

				svg path {
					fill:inherit;
				}

				use.sidebar-menu__ico {
					fill:@brc;
				}

				.sub__link {
					font-weight: 700;

					&:hover, &:focus {
						color:@hc;
					}
				}
			}

			&.sub {

				&:after {
					content:"";
					width: 0;
					height: 0;
					border-left: 3px solid transparent;
					border-right:3px solid transparent;
					border-top: 6px solid @hc;
					position: absolute;
					left:100px;
					top:9px;
				}
			}

			&.active {

				.sidebar-menu__img {
					svg path {
						fill:inherit;
					}

					use.sidebar-menu__ico {
						fill:@brc;
					}
				}

				.sidebar-menu__href {
					font-weight: 700;

					&:hover, &:focus {
						color:@hc;
					}
				}
			}
		}

		&__img {
			position: absolute;
			left:0;
			top: 1px;
			width: 20px;
			height:20px;

			svg path {
				fill:inherit;
			}

			use.sidebar-menu__ico {
				fill:#fff;
			}
		}
	}

	&-submenu {
		position: relative;
		list-style:none;
		padding-left: 0;
		display: none;

		&.active {
			display: block;
		}

		&__item {
			position: relative;
			cursor:pointer;
			margin-bottom: 14px;
			padding-left: 35;
			.transition;

			a {
				.font-style(16px,@tc,300,1.2,left);

				&.active {
					color:@hc;
					font-weight: 700;

					&:before {
						opacity:1;
					}
				}

				&:hover, &:focus {
					color:@hc;

					&:before {
						opacity:1;
					}
				}

				&:before {
					content:"";
					position: absolute;
					left:-15px;
					width: 4px;
					height:80%;
					top:50%;
					transform:translateY(-50%);
					background-color: @brc;
					opacity:0;
					.transition;
				}
			}

			&:first-child {
				margin-top: 25px;
			}

			&:last-child {
				margin-bottom: 0px;
			}
		}
	}
}


@media screen and (max-width: 768px) { 

	.sidebar {
		display: none;
	}
	
}