.intro {
	padding-top: 250px;
	padding-bottom: 190px;
	position: relative;

	.show-xs {
		display: none;
	}

	&-bg {
		position: absolute;
		right:0px;
		top:0;
		height: 910px;
	    width: 700px;
	    background-position: 75px -66px;

		&__img {
			position: absolute;
			width: 160%;
			height:100%;
			right:-60%;
			top:0;
		}
	}

	&-img-1 {
		position: absolute !important;
		left: -28px !important;
	    top:155px !important;
	    width: 131px;
    	height: 112px;

    	img {
    		width: 100%;
    		height:100%;
    	}
	}

	&-img-2 {
		position: absolute;
		left:-20px !important;;
		top:678px !important;;
	}

	&-img-3 {
		position: absolute;
		left:673px !important;;
		top:312px !important;;
		width: 16px;
		height:16px;

		img {
			width: 100%;
			height:100%;
		}
	}

	&-img-4 {
		position: absolute;
		left:640px !important;
		top:402px !important;
		width: 60px;
		height:60px;

		img {
			width: 100%;
			height:100%;
			transform:rotate(25deg);
		}
	}

	&-img-5 {
		position: absolute;
		left:auto !important;
		top:216px !important;
		right:-20px;
		width: 72px;
		height:72px;

		/* img {
			transform:rotate(45deg);
			height:100%;
		} */
	}

	&__h {
		padding-bottom: 88px;

		h1 {
			.font-style(48px,@hc,400,1.35,left);
		}
	}

	&-btns {
		position: relative;

		.btn-simple {
			margin-left: 18px;
		}
	}

	&-video {


		display: block;
		position: absolute;
		top: -80px;
    	bottom: 0;
    	left: 215px;
		right: 0;
		margin: auto;
		z-index: 3;
		width: 190px;
		height: 50px;

		@media(max-width: 768px) {
			left: 0;
			top: 400px;
		}


		&:after {
			content:"";
			display: inline-block;
			vertical-align: middle;
			height: 100%;
			width: 0;
		}

		&:hover {

			.intro-video__icon {
				svg path {
					fill:inherit;
				}

				use.video-arrow {
					fill:@brc;
				}
			}
			.intro-video__desc p {
				color:@brc;
			}
		}

		&__icon {
			display: inline-block;
			vertical-align: middle;
			position: relative;
			height:50px;
			width: 50px;
			cursor:pointer;
			text-decoration: none;

			&:hover {
				text-decoration: none;

				svg path {
					fill:inherit;
				}

				use.video-arrow {
					fill:@bc;
				}
			}

			svg path {
				fill:inherit;
			}

			use {
				fill:@hc;
				.transition;
			}
		}

		&__desc {
			display: inline-block;
			vertical-align: middle;
			position: relative;

			p {
				.font-style(16px,@hc,400,1.2,left);
				padding-left: 10px;
				letter-spacing: .5px;
				.transition;
			}
		}
	}

	@media screen and (max-width: 1200px) {

		.intro-bg {
			height: 757px;
    		width: 500px;
		}
	
	}

	@media screen and (max-width: 992px) {

		.intro {

			&__h h1 {
				font-size: 36px;
			}
		}

	}

	@media screen and (max-width: 768px) {

		.show-xs {
			display: block;
		}

		.hide-xs {
			display: none;
		}

		.intro {

			&-video {
				padding-top: 0;
				text-align: center;

				&__icon {
					width: 40px;
					height: 40px;
				}

				&__desc {

					p {
						font-size: 16px;
					}
				}
			}

			&-bg {
				height:623px;
				width: 100%;
				background-position: 0 0px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			&__h {

				h1 {
					text-align: center;
					font-size: 48px;
					@media(max-width: 768px) {
						font-size: 24px;
						max-width: 270px;
						margin-left: auto;
						margin-right: auto;
					}
				}
			}

			&-btns {
				padding-top:275px;

				.btn-primary, .btn-simple {
					padding: 0 15px;
					display: block;
					width: 270px;
					margin:0 auto;
					height: 62px;
					line-height: 62px;
					font-size: 16px;
					border-radius: 31px;
				}

				.btn-primary {
					margin-bottom: 30px;
				}
			}
		}

	}

	@media screen and (max-width: 480px) {

		.intro {

			&-img-1, &-img-2, &-img-3, &-img-4, &-img-5 {
				display: none;
			}

			&__h {
				padding-bottom: 40px;

				 h1 {
					font-size: 32px;
				}
			}

			&-bg {
				height:423px;
			}

			&-video {

				&__icon {
					width: 50px;
					height: 50px;
				}

				&__desc {

					p {
						font-size: 18px;
					}
				}
			}

			&-btns {

				.btn-simple, .btn-primary {
					width: 100%;
					padding:0;
					height: 65px;
				    line-height: 65px;
				    font-size: 16px;
				}
			}
		}

	}

	@media screen and (max-width: 360px) {

		.intro {
			
			&-bg {
				height: 365px;
			}
		}

	}
}

.parallax {

	.layer-mob {
		display: none !important;;
	}
}

@media screen and (max-width: 992px) {

	.intro {
		padding-top: 170px;
		padding-bottom: 200px;
	}

}

@media screen and (max-width: 768px) {

	.intro {
		padding-bottom: 0px;
	}

}


@media screen and (max-width: 480px) {

	.parallax {
		
		.layer {
			display: none !important;

			&.stay {
				display: block !important;
			}
		}

		.layer-mob {
			display: block !important;;
		}
	}

	.intro {
		padding-top: 85px;
	}

	.intro-video {
	    left: 0;
	    top: 195px;
	}
	.intro .intro-btns {
	    padding-top: 190px;
	}
}

@media screen and (max-width: 360px) {

	.intro {

		.intro-btns {
			padding-top: 150px;

			.btn-primary {
				margin-bottom: 30px;
			}
		}

		.intro__h h1 {
			font-size: 28px;
		} 
	}

}


#video-layer {
	.offers-popup__wrap {
		width: 850px;
		iframe {
			width:  100%;
			height: 480px;
		}

	}
	.offers-popup-close__line {
		height: 35px;
	}
	.offers-popup-close {
	    right: -45px;
    	top: -40px;
    	width: 50px;
    	padding-left: 25px;
	}
}
@media screen and (max-width: 950px) {
	#video-layer .offers-popup__wrap {
		width: 500px;
		iframe {
			height: 280px;
		}

	}
}
@media screen and (max-width: 600px) {
	#video-layer {
		.offers-popup__wrap, .offers-popup {
			width: 100%;
		}
		.offers-popup__wrap {
			iframe {
				height: 300px;
			}
			
		}
		.offers-popup-close {
			right: 10px;
    		top: -48px;
		}
	}
}

@media screen and (max-width: 500px) {
	#video-layer {
		.offers-popup__wrap {
			iframe {
				height: 240px;
			}
		}
	}
}
@media screen and (max-width: 350px) {
	#video-layer {
		.offers-popup__wrap {
			iframe {
				height: 185px;
			}
		}
	}
}
#booking-container {
	// position: relative;
 //    padding-bottom: 56.25%;  proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) 
 //    padding-top: 30px;
 //    height: 0;
 //    overflow: hidden;
}
#booking-iframe {
  	width: 100%;
  	height: 1255px;
  	// height: max-content;

}
@media screen and (max-width: 1199px) {
  #booking-iframe {
    height: 1095px;
  }
}
@media screen and (max-width: 991px) {
  #booking-iframe {
    height: 1877px;
  }
}
@media screen and (max-width: 617px) {
  #booking-iframe {
    height: 1917px;
  }
}
@media screen and (max-width: 464px) {
  #booking-iframe {
    height: 1960px;
  }
}
#open-video1 {
	width: 100%;
	align-items: center;
	display: flex;
	flex-direction: row;
	svg {
		width: 50px;
		height: 50px;
	}
}
#open-video2 {
	width: 100%;
	align-items: center;
	display: flex;
	flex-direction: row;
	svg {
		width: 40px;
		height: 40px;
	}
}
#booking-content {
	margin-bottom: 50px;
	h1 {
	 	font-size: 36px;
	    color: #FFFFFF;
	    font-weight: 400;
	    line-height: 1.35;
	    text-align: left;
	    margin-bottom: 20px;
		
	}
    .booking-text {
    	font-size: 18px;
	    color: #b1b1b1;
	    font-weight: 400;
	    line-height: 2;
	    font-family: 'Nunito', sans-serif;
    	text-align: left;
    }
}