.error {
    position: relative;
    padding-top: 250px;
    padding-bottom: 140px;

    &-img-1 {
        position: absolute !important;
        left: -28px !important;
        top: 180px !important;
        width: 131px;
        height: 112px;

        img {
            width: 100%;
            height:100%;
        }
    }

    &-img-2 {
        position: absolute;
        left:auto !important;
        top:216px !important;
        right:-20px !important;;;
        width: 72px;
    }

    &-img-3 {
        position: absolute;
        left:130px !important;
        top:120px !important;
        width: 16px;
        height:16px;

        img {
            width: 100%;
            height:100%;
        }
    }

    &-img-4 {
        position: absolute;
        left:auto !important;
        top:-30px !important;
        right:-30px;
        width: 16px;
        height:16px;

        img {
            width: 100%;
            height:100%;
        }
    }

    &-img-5 {
        position: absolute;
        left:auto !important;
        top:30px !important;
        right:75px;
        width: 70px;
        height:70px;

        img {
            width: 100%;
            height:100%;
            transform:rotate(15deg);
        }
    }

    &-img-6 {
        position: absolute;
        left:auto !important;
        top:187px !important;
        right:-20px;
        width: 16px;
        height:16px;

        img {
            width: 100%;
            height:100%;
        }
    }

    &-img-7 {
        position: absolute;
        top:113px !important;
        left:306px !important;
        width: 16px;
        height:16px;

        img {
            width: 100%;
            height:100%;
        }
    }

    &-img-8 {
        position: absolute;
        top:165px !important;
        left:0px !important;
        width: 80px;
        height:45px;

        img {
            width: 100%;
            height:100%;
        }
    }

    &-img-9 {
        position: absolute;
        top:210px !important;
        left:320px !important;
    }

    &-img-10 {
        position: absolute;
        top:255px !important;
        left:179px !important;
        width: 60px;
        height:60px;

        img {
            width: 100%;
            height:100%;
            transform:rotate(25deg);
        }
    }

    &-img-11 {
        position: absolute;
        top:350px !important;
        left:auto !important;
        right:0 !important;
        width: 16px;
        height:16px;

        img {
            width: 100%;
            height:100%;
        }
    }

    &-img-12 {
        position: absolute;
        top:450px !important;
        left:auto !important;
        right:192px !important;
        width: 60px;
        height:60px;

        img {
            width: 100%;
            height:100%;
            transform:rotate(25deg);
        }
    }

    &-img-13 {
        position: absolute;
        top:560px !important;
        left:30px !important;

        img {
            transform:rotate(15deg);
        }
    }

    &-img-14 {
        position: absolute;
        top:682px !important;
        left:0px !important;
    }


    &-block {
        width: 600px;
        max-width: 100%;
        margin:0 auto;
        position: relative;

        &__img {
            width: 100%;
        }

        &__btn {
            text-align: center;
        }

        &__desc {
            padding-top: 65px;
            padding-bottom: 65px;

            p {
                .font-style(24px,@tc,400,1.4,center);
            }
        }
    }
}

@media screen and (max-width: 768px) {

    .error {
        padding-top: 130px;
        padding-bottom: 60px;

        &-block__desc {
            padding-top: 45px;
            padding-bottom: 45px;
        }
    }

}

@media screen and (max-width: 480px) {

    .error {

        &-img-4 {
            right:30px !important;
        }

        &-img-5 {
            right:50px !important;
        }

        &-img-6 {
            top: 87px !important;
        }

        &-img-7 {
            left:150px !important;
            top:62px !important;
        }

        &-img-8 {
            top:75px !important;
        }

        &-img-10 {
            top: 120px !important;
            left: 79px !important;
            width: 45px;
            height:45px;
        }
    }

}