.cf(){
    *zoom: 1;

    &:before, &:after{
        content: " ";
        display: table;
    }

    &:after{
        clear: both;
    }
}

.container {
  padding-left: 0;
  padding-right: 0;
}

.s-back-switch {
  background: center;
  background-size: cover;
  background-repeat: no-repeat;
}


.font-style(@size, @colour, @weight,  @lh, @text) {

  font-size: @size;
  color: @colour; 
  font-weight: @weight;
  line-height: @lh;
  text-align: @text;

}

.placeholder(@color) {
    &::-webkit-input-placeholder {
        color: @color;
        transition: all ease .5s; 
    }
    &:-moz-placeholder {
        color: @color;
        transition: all ease .5s; 
    }
}

.font-1 {
    font-family: 'Chelsea Market', cursive;
}

.font-2 {
    font-family: 'Source Sans Pro', sans-serif;

}


.icon-svg {
    svg {
        width: 100%;
        max-width: 100%;
        height: 100%;
    }
}

.wrap-oh{
    overflow: hidden;
}

.transition {
	transition: all ease .5s; 
}