.order {

	&-block {
		position: relative;
		padding-top: 72px;
		padding-bottom: 57px;

		&__h {
			padding-bottom: 30px;

			h3 {
				.font-style(30px,@hc,700,1.35,left);
			}
		}

		&__desc {
			position: relative;
			padding-bottom: 15px;

			&:last-child {
				padding-bottom: 0;
			}

			p {
				.font-style(24px,@tcl,400,1.35,left);
			}
		}
	}
}


@media screen and (max-width: 992px) {

	.order-block {
		padding-top: 25px;
		padding-bottom: 25px;

		&__h {
			padding-bottom: 25px;

			h3 {
				font-size: 24px;
				text-align: center;
			}
		}

		&__desc {
			padding-bottom: 10px;

			p {
				font-size: 18px;
				text-align: center;
			}
		}
	}

}
.red-october {
	color: #eb0029;	
}
