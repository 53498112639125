.thanks {
    position: relative;
    padding-top: 255px;
    z-index: 1;


    &-img-mob-1 {
        position: absolute !important;
        left: 97% !important;
        top: 300px !important;
        width: 16px;
        height: 16px;

        img {
            width: 100%;
            height:100%;
        }
    }

    &-img-mob-2 {
        position: absolute;
        left: 55px !important;
        top: 350px !important;
        width: 36px;

        img {
            transform:rotate(25deg);
        }
    }

    &-img-1 {
        position: absolute !important;
        left: -28px !important;
        top: 188px !important;
        width: 131px;
        height: 112px;

        img {
            width: 100%;
            height:100%;
        }
    }

    &-img-2 {
        position: absolute;
        right:200px !important;
        left:auto !important;
        top:-78px !important;
        width: 16px;
        height: 16px;

        img {
            width: 100%;
            height:100%;
        }

    }

    &-img-3 {
        position: absolute;
        right:-20px !important;
        left:auto !important;
        top: 188px !important;
        width: 56px;
    }

    &-img-4 {
        position: absolute;
        left:576px !important;
        top: 372px !important;
        width: 16px;
        height: 16px;

        img {
            width: 100%;
            height:100%;
        }
    }

    &-img-5 {
        position: absolute;
        left:576px !important;
        top: 502px !important;
        width: 60px;
        height: 60px;

        img {
            width: 100%;
            height:100%;
            transform:rotate(25deg);
        }
    }

    &-img-6 {
        position: absolute;
        left: 0px !important;
        top: 600px !important;
    }

    &-img-7 {
        position: absolute;
        left: 180px !important;
        top: 660px !important;

        img {
            transform:rotate(25deg);
        }
    }

    &-img-8 {
        position: absolute;
        left: 70% !important;
        top: 358px !important;
        width: 16px;
        height: 16px;

        img {
            width: 100%;
            height:100%;
        }
    }

    &-img-9 {
        position: absolute;
        left: 70% !important;
        top: 150px !important;
        width: 16px;
        height: 16px;

        img {
            width: 100%;
            height:100%;
        }
    }

    &-img-10 {
        position: absolute;
        left: auto !important;
        top: auto !important;
        right:0 !important;
        bottom: -100px !important;
        width: 16px;
        height: 16px;

        img {
            width: 100%;
            height:100%;
        }
    }

    &-img-11 {
        position: absolute;
        left: auto !important;
        top: auto !important;
        right:80px !important;
        bottom: -200px !important;
        width: 16px;
        height: 16px;

        img {
            width: 100%;
            height:100%;
        }
    }

    &-img-12 {
        position: absolute;
        left: 145px !important;
        top: 525px !important;
        width: 72px;
        height: 96px;

        img {
            width: 100%;
            height:100%;
            transform:rotate(15deg);
        }
    }

    &-img-13 {
        position: absolute;
        left: auto !important;
        top: auto !important;
        right:0 !important;
        bottom: 600px !important;
        width: 16px;
        height: 16px;

        img {
            width: 100%;
            height:100%;
        }
    }

    &-img-14 {
        position: absolute;
        left: auto !important;
        top: auto !important;
        right:80px !important;
        bottom: 500px !important;
        width: 16px;
        height: 16px;

        img {
            width: 100%;
            height:100%;
        }
    }

    &-img-15 {
        position: absolute;
        left: auto !important;
        top: 730px !important;
        right:330px !important;
        width: 16px;
        height: 16px;

        img {
            width: 100%;
            height:100%;
        }
    }

    &-block {
        padding-top: 65px;
        padding-right: 70px;

        &__h {
            padding-bottom: 25px;

            h3 {
                .font-style(60px,#fff,400,1.4,left);
            }
        }

        &__desc {

            p {
                .font-style(24px,@tc,400,2,left);
            }
        }
    }

    &-img {
        padding-left: 30px;
    }

    &-next {
        position: relative;
        padding-top: 175px;
        padding-bottom: 140px;

        &.fix-centr {
            width: 566px;
            margin:0 auto;
            max-width:100%;
        }

        &__h {
            padding-bottom: 55px;

            h3 {
                .font-style(36px,#fff,400,1.4,center);

                &.t-left {
                    text-align: left;
                }
            }
        }

        &__btns {
            text-align: center;

            &.pd-top {
                padding-top: 70px;
                text-align: left;
            }

            &:after {
                content:"";
                display: inline-block;
                width: 0;
                height:100%;
            }

            .btn-simple {
                margin-left: 15px;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    
    .thanks {

        &-block {
            padding-top: 50px;
            padding-right: 0;
        }
    }
}

@media screen and (max-width: 992px) {

    .thanks {

        &-block {

            &__h {

                h3 {
                    font-size: 36px;
                    text-align: center;
                }
            }

            &__desc {

                p {
                    font-size: 18px;
                    text-align: center;
                }
            }
        }

        &-next {
            padding-top: 70px;
            padding-bottom: 50px;

            &.fix-centr {
                width: 100%;
            }

            &__h {

                h3 {
                    font-size: 24px;

                    &.t-left {
                        text-align: center;
                    }
                }
            }

            &__btns.pd-top {
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: 768px) {

    .thanks {
        padding-top: 110px;

        &-img {
            padding-left: 0;
            width: 270px;
            margin:0 auto;
            max-width:100%;
        }

        &-block {
            width: 300px;
            margin:0 auto;
            max-width:100%;
        }

        &-next.fix-centr {
            width: 370px;
        }
    }

}

@media screen and (max-width: 480px) {

    .thanks {
        padding-top: 110px;

        &-img {
            padding-left: 0;
            width: 270px;
            margin:0 auto;
            max-width:100%;
        }

        &-block {
            width: 300px;
            margin:0 auto;
            max-width:100%;
        }

        &-next {

            &__h {
                padding-bottom: 35px;
            }

            &__btns {
                
                &.pd-top {
                    padding-top: 50px;
                }

                .btn-simple {
                    margin-left: 0;
                    padding: 0 75px;
                    margin-top: 15px;
                }

                .btn-primary {
                    padding: 0 55px;
                }
            }
        }
    }

}

