.discount {
	position: relative;
	padding-top: 224px;

	.row {
		position: relative;
	}

	&-img-mob-1 {
		position: absolute;
		left: 96% !important;
    	top: 180px !important;
    	width: 16px;
    	height: 16px;

    	img {
    		width: 100%;
    		height:100%;
    	}
	}

	&-img-mob-2 {
		position: absolute;
		right:0 !important;;
		left: 88px !important;
	    top: 413px !important;
	}

	&-img-mob-3 {
		position: absolute;
		left: 112px !important;
	    top: 741px !important;
	    width: 45px;
	}

	&-img-1 {
		position: absolute;
		left: -28px !important;
	    top: 180px !important;
	    width: 131px;
    	height: 112px;

		img {
			width: 100%;
			height:100%;
		}
	}

	&-img-2 {
		position: absolute;
		left:-20px !important;;
		top:678px !important;;
	}

	&-img-3 {
		position: absolute;
		left: 570px !important;
    	top: 410px !important;
    	width: 16px;
    	height: 16px;

		img {
			width: 100%;
			height:100%;
		}
	}

	&-img-4 {
		position: absolute;
		left:640px !important;
		top:490px !important;
		width: 60px;
    	height: 60px;

		img {
			width: 100%;
			height:100%;
			transform:rotate(25deg);
		}
	}

	&-img-5 {
		position: absolute;
		left:auto !important;
		top:216px !important;
		right:-20px !important;;;
		width: 72px;
	}

	&-img-6 {
		position: absolute;
		left:56px !important;
		top:-66px !important;

		img {
			transform:rotate(25deg);
		}
	}

	&-img-7 {
		position: absolute;
		left:auto !important;
		right:0px !important;
		top:402px !important;
	}

	&-img-8 {
		position: absolute;
		left:100% !important;
		top:502px !important;
		width: 16px;
    	height: 16px;

    	img {
    		width: 100%;
    		height:100%;
    	}
	}

	&-img-9 {
		position: absolute;
	    left: 30px !important;
	    right: 0 !important;
	    top: 445px !important;
	    z-index: 1;
	    width: 75px;
    	height: 90px;

    	img {
    		width: 100%;
    		height:100%;
    		transform:rotate(15deg);
    	}
	}

	&-img-10 {
		position: absolute;
		left:auto !important;
		right:-4px !important;
		top:1239px !important;
		width: 16px;
    	height: 16px;

    	img {
    		width: 100%;
    		height:100%;
    	}
	}

	.mr-bot {
		margin-bottom: 140px;
	}

	&-block {
		position: relative;
		padding-top: 127px;

		&.right {
			padding-top: 87px;
		}

		&__h {
			padding-bottom: 25px;

			h3 {
				.font-style(36px,#fff,400,1.4,left);
			}
		}

		&__desc {

			p {
				.font-style(18px,@tc,400,2,left);
			}
		}

		&-menu {
			padding-left: 0;
			list-style:none;
			padding-top: 14px;

			&.no-pd-top {
				padding-top: 0;
			}

			&__item {
				.font-style(18px,@tc,400,1.4,left);
				position: relative;
				padding-left: 35px;
				padding-bottom: 24px;

				&:last-child {
					padding-bottom: 0;
				}

				&:before {
					content:"";
					position: absolute;
					left:0;
					top:9px;
					border:3px solid @brc;
					border-radius:100%;
					width: 9px;
					height:9px;
					background-color: @bc;
				}
			}
		}
	}

	&-img {
		position: relative;

		&__item {
			position: relative;
			width: 645px;
    		height: 482px;

    		img {
    			width: 100%;
    		}

			&.right {
				// right:-105px;
			}

			&.left {
				// left:-175px;
			}
		}
	}
}


@media screen and (max-width: 1200px) {
	
	.discount-img__item.left {
		left: -275px;
	}


}

@media screen and (max-width: 992px) {
	
	.discount-img__item.right {
		right: 0px;
	}

	.discount-img__item.left {
		left: 0px;
	}

	.discount-img__item {
		width: 100%;
		height:auto;

		img {
			height:auto;
		}
	}

	.discount-block {
		padding-top: 100px;

		&.right {
			padding-top: 100px;
		}

		&__h {

			h3 {
				font-size: 24px;
				text-align: center;
			}
		}

		&__desc {
			ul {
				list-style: none;
				padding: 0;
			}
			p {
				text-align: center;
			}
		}
	}

	.discount .mr-bot {
		margin-bottom: 100px;
	}


}

@media screen and (max-width: 992px) {

	.discount-img {
		width: 340px;
		margin:0 auto;
		max-width:100%;
	}

	.discount-block {
		padding-top: 60px;
		width: 340px;
		margin:0 auto;
		max-width:100%;
	}

}

@media screen and (max-width: 768px) {

	.discount {
		padding-top: 130px;

		.mr-bot {
			margin-bottom: 60px;
		}
	}

	.discount-block {
		padding-top: 60px;

		&.right {
			padding-top: 60px;
		}
		
	}

}

@media screen and (max-width: 360px) {

	.discount-block {

		&__desc {

			p {
				font-size: 16px;
			}
			ul {
				list-style: none;
				padding: 0;
			}
		}

		&-menu {

			&__item {
				font-size: 16px;
				padding-bottom: 15px;
			}
		}
	}

}
.discount-block__desc ul {
	list-style: none;
	padding: 0;
}
.discount-p-right {
	position: relative;
	right: -15px;
}
.location-img {
	max-width: 600px;
}
@media screen and (max-width: 992px) {
	.location-img {
		display: block !important;
		margin: auto;
	}
	.location-img img{
		max-width: 100%;
		display: block;
		margin: auto;
	}
	.discount-p-right {
		right: auto;
	}
	.discount-block__desc {
		text-align: center;
		.listtype-bullet {
			display: inline-block;
		}
	}
}