.customer {
	position: relative;

	&-bg-1 {
		position: absolute;
		top:160px !important;
		left:-178px !important;
		width: 60px;
		height:60px;

		img {
			width: 100%;
			height:100%;
		}
	}

	&-bg-2 {
		position: absolute;
		top:360px !important;
		left:-78px !important;

		img {
			width: 100%;
			height:100%;
			transform:rotate(15deg);
		}
	}

	&-bg-3 {
		position: absolute;
		left:auto !important;
		top:320px !important;
		right:178px !important;
		width: 75px;
    	height: 90px;

		img {
			width: 100%;
			height:100%;
			transform:rotate(15deg);
		}
	}

	&-bg-4 {
		position: absolute;
		left:95% !important;
		top:185px !important;
		width: 45px;
    	height: 45px;

		img {
			width: 100%;
			height:100%;
		}
	}

	&-bg-5 {
		position: absolute;
		left:auto !important;
		top:235px !important;
		right:-45px !important;
		width: 105px;
    	height: 105px;

		img {
			width: 100%;
			height:100%;
		}
	}

	&-bg-6 {
		position: absolute;
		left: -35px !important;
    	top: -40px !important;
		display: none;
	}

	&-bg-7 {
		position: absolute;
		left:-10px !important;
		top:-30px !important;
		display: none;
	}

	&-bg-8 {
		position: absolute;
		left:-10px !important;
		top:130px !important;
		display: none;
		width: 35px;
		height:35px;

		img {
            width: 100%;
            height:100%;
        }
	}

	&-bg-9 {
		position: absolute;
		left:auto !important;
		right: 45px !important;
	    top: 143px !important;
	    width: 40px;
		display: none;
	}

	&-form {
		width: 766px;
		margin:0 auto;
		position: relative;
		background-position: 0 -27px;
		padding-bottom: 100px;
		padding-top: 45px;

		&__h {
			padding-bottom: 25px;

			h2 {
				.font-style(36px,@hc,400,1.2,center);
			}
		}

		&__desc {
			padding-bottom: 75px;
			
			p {
				.font-style(16px,#B8B8B8,400,26px,center);
			}
		}

		&__wrap {
			width: auto;
			margin:0 -12px;
			position: relative;
			.cf();
		}

		&__field {
			padding:0 12px;
			width: 38%;
			float:left;

			input {
				background-color: transparent;
				border-radius:30px;
				border:1px solid @tc;
				line-height: normal;
				font-size: 16px;
				font-weight: 300;
				color:@tc;
				padding-left: 20px;
				height: 50px;
				outline: none;
				.transition;
				.placeholder(@tc);
				position: relative;
				display: block;
				width: 100%;

				&:focus, &:hover {
					color:@hc;
					border-color:@hc;
					.placeholder(@hc);
				}
			}
		}

		&__btn {
			width: 24%;
			float:left;
			padding:0 12px;

			.btn-primary {
				width: 100%;
				height:50px;
				line-height: 50px;
				padding-left: 0;
				padding-right: 0;
				text-align: center;
			}
		}
	}

	@media screen and (max-width: 992px) {

    	.customer-form {
    		width: 430px;
    		padding-bottom: 100px;

    		&__wrap {
    			margin:0;
    		}

    		&__field {
    			float:none;
    			width: 100%;
    			padding-bottom: 20px;
    		}

    		&__btn {
    			width: 100%;

    		}
    	}
    }

    @media screen and (max-width: 992px) {

    	.customer-form {
    		width: 100%;
    		padding-bottom: 100px;
    		background-size: 100%;

    		&__cont {
    			width: 430px;
    			margin:0 auto;
    		}

    		&__wrap {
    			margin:0;
    		}

    		&__field {
    			float:none;
    			width: 100%;
    			padding-bottom: 20px;
    		}

    		&__btn {
    			width: 100%;
    		}
    	}
    }

    @media screen and (max-width: 480px) {


    	.customer-bg-5 {
    		display: none;
    	}

    	.customer-bg-6, .customer-bg-8, .customer-bg-9, .customer-bg-10  {
    		display: block;
    	}

    	.customer-form {
			background-size: 150%;
			background-position: -111px 23px;
			padding-bottom: 70px;

			&__h h2 {
				font-size: 24px;
			}

			&__desc {
				padding-bottom: 45px;
				
				p {
					font-size: 16px;
				}
			}

			&__field {

				input {
					height:60px;
					font-size: 15px;
				}
			}

			&__btn {
				
				.btn {
					height:60px;
					line-height: 60px;
					font-size: 15px;
					border-radius:30px;
				}
			}

    		&__cont {
    			width: 100%;
    			padding-left: 12px;
    			padding-right: 12px;
    		}
    	}

    }
}
.customer-form .result {
	display: none;
	text-align: center;
    height: 50px;
    p {
    	font-size: 30px;
    	margin: 7px 0; 
    }
}
input.has-error, textarea.has-error {
	border: 1px solid #eb0029 !important;
	color: #eb0029;
	&::-webkit-input-placeholder { 
	  color: #eb0029;
	}
	&::-moz-placeholder { 
	  color: #eb0029;
	}
	&:-ms-input-placeholder { 
	  color: #eb0029;
	}
	&:-moz-placeholder { 
	  color: #eb0029;
	}
}