
/*
================================================================================
|                                     WRAPPER                                  |
================================================================================
*/

.container {
    position: relative;
    .cf();
}

.main-wrapper,{
    padding: 0 0 0 0;
    min-width: 320px;
    position: relative;
    min-height:100%;
    background-color:#080808;
}



/*
================================================================================
|                                     HEADER                                   |
================================================================================
*/
header.main-header {
    width:100%;
    min-width: 320px;
    left:0px; top:0px;
    z-index:3;}

/*
================================================================================
|                                     CONTENT                                 |
================================================================================
*/

main.content {
    width:100%;
    min-width:320px;
    position: relative;
    display: block;
    height: 100%;
    overflow: hidden;
}

/*
================================================================================
|                                     FOOTER                                   |
================================================================================
*/

footer.main-footer {
    height:auto; 
    left:0px; bottom:0px;
    width:100%;
    min-width: 320px;
}