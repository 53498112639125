.about {
	position: relative;
	padding-top: 210px;
	background-color: #050607;
	z-index: 1;

	.container, .row {
		position: relative;
	}

	.layer {
		z-index: 3;
	}

	&-bg-mob-1 {
		position: absolute;
		z-index: 3;
		top:350px !important;
		left:-30px !important;
		width: 70px;
		display: none;
	}

	&-bg-mob-2 {
		position: absolute;
		z-index: 3;
		top:70px !important;
		right:110px !important;
		width: 10px ;
		display: none;
	}

	&-bg-mob-3 {
		position: absolute;
		z-index: 3;
		left:auto !important;
		top:150px !important;
		right:-3px !important;
		display: none;
		width: 12px;
    	height: 12px;

		img {
			width: 100%;
			height:100%;
		}
	}

	&-bg-mob-4 {
		position: absolute;
		z-index: 3;
		top: 312px !important;
    	left: 67px !important;
    	display: none;
    	width: 12px;
    	height: 12px;

		img {
			width: 100%;
			height:100%;
		}
	}

	&-bg-mob-5 {
		position: absolute;
		z-index: 3;
		top: 347px !important;
    	left: 97px !important;
    	display: none;
    	width: 35px;
    	height: 35px;

		img {
			width: 100%;
			height:100%;
		}
	}

	&-bg-mob-6 {
		position: absolute;
		z-index: 3;
		top: 0px !important;
    	left: 40px !important;
    	width: 30px;
    	display: none;
	}

	&-bg-mob-7 {
		position: absolute;
		z-index: 3;
		left:auto !important;
		top: 115px !important;
    	right: 75px !important;
    	width: 30px;
    	display: none;
	}

	&-bg-mob-8 {
		position: absolute;
		z-index: 3;
		top: 225px !important;;
	    left: 85px !important;;
	    width: 30px;
	    display: none;
	}

	&-bg-1 {
		position: absolute !important;
		left: -28px !important;
	    top: 192px !important;
	    width: 131px;
    	height: 112px;

		img {
			width: 100%;
			height:100%;
		}
	}

	&-bg-2 {
		position: absolute !important;
		left:auto !important;
		top:216px !important;
		right:-20px;
		width: 72px;
	}

	&-bg-3 {
		position: absolute !important;
		left:0px !important;
		top:678px !important;
	}

	&-bg-4 {
		position: absolute !important;
		left:573px !important;
		top:418px !important;
		width: 16px;
    	height: 16px;

		img {
			width: 100%;
			height:100%;
		}
	}

	&-bg-5 {
		position: absolute !important;
		left:640px !important;
		top:506px !important;
		width: 60px;
    	height: 60px;

		img {
			width: 100%;
			height:100%;
			transform:rotate(25deg);
		}
	}

	&-bg-6 {
		position: absolute !important;
		left:887px !important;
		top:-6px !important;
		width: 16px;
    	height: 16px;

		img {
			width: 100%;
			height:100%;
		}
	}

	&-bg-7 {
		position: absolute !important;
		left: 65px !important;
    	top: 1px !important;

    	img {
    		transform:rotate(15deg);
    	}
	}

	&-bg-8 {
		position: absolute !important;
		left: 396px !important;
    	top: 260px !important;
	}

	&-bg-9 {
		position: absolute !important;
		left: 154px !important;
    	top: 496px !important;
    	width: 75px;
    	height: 90px;

    	img {
    		width: 100%;
    		height:100%;
    		transform:rotate(15deg);
    	}
	}

	&-bg-10 {
		position: absolute !important;
		left:auto !important;
		right:-4px !important;
		top:1240px !important;
		width: 16px;
    	height: 16px;

		img {
			width: 100%;
			height:100%;
		}
	}

	&-bg-11 {
		position: absolute !important;
		left:auto !important;
		right:75px !important;
		top: 1367px !important;
		width: 16px;
    	height: 16px;

		img {
			width: 100%;
			height:100%;
		}
	}

	&-bg-12 {
		position: absolute !important;
		left:auto !important;
		right:220px !important;
		top:-140px !important;
		width: 35px;
    	height: 35px;

		img {
			width: 100%;
			height:100%;
		}
	}

	&-bg-13 {
		position: absolute !important;
		left:340px !important;
		top:0px !important;
		width: 16px;
    	height: 16px;

		img {
			width: 100%;
			height:100%;
		}
	}

	&-bg-14 {
		position: absolute !important;
		left:-80px !important;
		top:390px !important;
		width: 16px;
    	height: 16px;

		img {
			width: 100%;
			height:100%;
		}
	}

	&-bg-15 {
		position: absolute !important;
		left:575px !important;
		top:400px !important;
	}

	.mb-37 {
		margin-bottom: 37px;
	}

	.mb-160 {
		margin-bottom: 160px;
	}

	&-img {
		margin-left: 50px;
		position: relative;
		right: -15px;
		&.right {
			margin-left: -15px;
		}
		img {
			max-width: 600px;
		}
	}

	&__h {
		margin-top: 140px;
		padding-bottom: 95px;

		h2 {
			.font-style(36px,#fff,400,1.4,center);
		}
	}

	&-txt {
		position: relative;
		padding-top: 103px;

		&.right {
			padding-top: 135px;
		}

		&__h {
			padding-bottom: 25px;

			h3 {
				.font-style(36px,#fff,400,1.4,left);
			}
		}

		&__desc {

			p {
				.font-style(18px,@tc,400,2,left);
				font-family: 'Nunito', sans-serif;
			}
		}
	}

	&-item {
		position: relative;

		&__h {
			padding-bottom: 20px;

			h3 {
				.font-style(24px,#fff,400,1.4,left);
			}
		}

		&__desc {
			padding-right: 25px;

			p {
				.font-style(18px,@tc,400,2,left);
			}
		}
	}

	&-team {
		position: relative;
		padding-bottom: 105px;
		img {
			display: block;
    		margin: auto;
    		max-width: 250px;
		}
		&__h {
			padding-top: 40px;

			h3 {
				.font-style(24px,#fff,400,1.4,center);
			}
		}

		&__desc {

			p {
				.font-style(16px,@tc,400,1.8,center);
				// text-transform: uppercase;
			}
		}
	}
}


@media screen and (max-width: 992px) {

	.about {
		.about-img {
			right: auto;
		}
		.about-img img {
		    max-width: 600px;
		    margin: auto;
		    display: block;
		}

		.mb-160 {
			margin-bottom: 100px;
		}

		&__h {
			margin-top: 100px;
			padding-bottom: 70px;

			h2 {
				font-size: 24px;
			}
		}
		
		.mb-37 {
			margin-bottom: 150px;
		}

		&-img {
			margin-left: 0;

			&.right {
				margin-left: 0;
			}
		}

		&-txt {
			padding-top: 100px;

			&.right {
				padding-top: 100px;
			}
			
			&__h {

				h3 {
					font-size: 24px;
					text-align: center;
				}
			}

			&__desc {

				p {
					text-align: center;
				}
			}
		}

		&-item {

			&__desc {
				padding-right: 0;
			}
		}

		&-team {
			padding-bottom: 70px;

			&__h {

				h3 {
					font-size: 20px;
				}
			}

			&__desc {

				p {
					font-size: 14px;
				}
			}
		}
	}

}


@media screen and (max-width: 768px) {

	.about {

		padding-top: 110px;

		.about-img img {
			max-width: 100%;
		}

		.mb-37 {
			margin-bottom: 70px;
		}

		.mb-160 {
			margin-bottom: 80px;
		}

		&__h {
			margin-top: 20px;
		}

		&-txt {
			padding-top: 60px;

			&.right {
				padding-top: 60px;
			}

			&__desc {
				width: 300px;
				max-width:100%;
				margin:0 auto;
			}
		} 

		&-img {
			width: 270px;
			margin:0 auto;

			&.right {
				margin:0 auto;
			}
		}

		&-item {

			&__h {

				h3 {
					text-align: center;
				}
			}

			&__desc {
				width: 250px;
				margin:0 auto;
				max-width:100%;
				padding-bottom: 60px;

				p {
					text-align: center;
				}
			}
		}

		&-team {

			&__img {
				width: 250px;
				margin:0 auto;
			}

			&__desc {

				&.last {
					padding-bottom: 0;
				}
			}
		}
	}


}


@media screen and (max-width: 480px) {

	.about {

		&-bg-mob-1, &-bg-mob-2, &-bg-mob-3, &-bg-mob-4, &-bg-mob-5, &-bg-mob-6,
		&-bg-mob-7, &-bg-mob-8  {
			display: block;
		}
	}

}

@media screen and (max-width: 360px) {
	
	.about {

		&-txt {

			&__desc {

				p {
					font-size: 16px;
				}
			}
		}

		&-item {

			&__desc {

				p {
					font-size: 16px;
				}
			}
		}
	}
}